import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Components } from "../components";
import { Services } from "../services";
import { Utils } from "../utils";
import { ArticleItem } from "../components/ArticleItem";

export function AideView(props){
   

  

  
    

   
    
   return (
    <div className="col-span-12 mt-8">
       
       <div style={{position: "relative"}} >
        <iframe
            title="Legafrik Aide"
            src="https://www.legafrik.com/aide-principale"
            style={{width: "100%", height: "100vh", display: "block", border: "none"}}
        />
        </div>
           
    </div>
          
   )
}