import { useEffect, useState } from "react";
import { Components } from "../components";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Hooks } from "../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { CinetPaySerivce } from "../services/CinetpayService";
import Swal from "sweetalert2";

const stripePromise = loadStripe(process.env.REACT_APP_API_STRIPE_KEY);

export function PaiementCheckoutView(props) {
  const [tokenUser, setTokenUser] = useState("");
  const { token } = useParams();

  const navigate = useNavigate();
  // const montant = useSearchParams()[0].get("montant");
  const usePaiement = Hooks.usePaiement();

  const [demande, setDemande] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [moyen_paiement, setMoyen_paiement] = useState({});
  const [moyen_paiements, setMoyen_paiements] = useState([]);

  const handlePaymentSubmit = async (e, payment_data) => {
    // console.log("payment_data", payment_data);
    // console.log("moyen_paiement", moyen_paiement);

    e.preventDefault();

    if (usePaiement.isDisabled) return;

    let payload = {
      type_paiement_id: 1,
      moyen_paiement_id: payment_data.id ? payment_data.id : moyen_paiement.id,
      // demande_id: demande.id,
      amount: usePaiement.montant,
      currency: payment_data.currency,
      source: payment_data.source,
      description: "Paiement",
    };
    // console.log("payload", payload);
    // payment_data["pays"] = JSON.stringify(demande.pays);

    try {
      usePaiement.setIsDisabled(true);

      fetch(`${process.env.REACT_APP_HOST}/api/org_token/paiement/${token}`, {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then(async (data) => {
          window.location.href = data.url_redirect;
          // navigate.loca(``);

          // console.log("data", data);
        });
    } catch (error) {
      usePaiement.setIsDisabled(false);
      if ("messages" in error) return; //Toast
    }
  };

  const handlePaymentMethodClick = async (e, moyen_paiements) => {
    e.preventDefault();
    // console.log("moyen_paiement", moyen_paiements);
    setMoyen_paiement(moyen_paiements);
    setIsModalOpen(true);

    const payment_data = {
      id: moyen_paiement.id,
      moyen_paiement_id: moyen_paiements.id,
      currency: demande.pays.monnaie,
      description: "This is test payment",
    };

    let libelle;
    if (moyen_paiement?.libelle === "Payer plus tard") {
      libelle = 0;
    } else {
      libelle = usePaiement.montant;
    }

    const payload = {
      id: Math.round(Math.random() * 1000000).toString(),
      amount: libelle,
      payment_data: payment_data,
      moyen_paiement_id: moyen_paiements.id,
    };

    if (moyen_paiement.libelle?.includes("plus tard")) {
      await handlePaymentSubmit(e, payload);
    }

    if (moyen_paiement.libelle?.includes("Mobile")) {
      try {
        await new CinetPaySerivce().makePayment(payload);
        await handlePaymentSubmit(e, payload);
      } catch (error) {
        usePaiement.setIsDisabled(false);
        if ("messages" in error) return; //Toast
      }
    }
  };

  const handleModalClose = (e) => {
    setIsModalOpen(false);
    setMoyen_paiement("");
  };

  useEffect(() => {
    // const CheckoutGenerateToken = async () => {
    //   try {
    //     // https://api.datagreffe.ci/recouv/search
    //     fetch(`${process.env.REACT_APP_HOST}/api/org_token/generate/token`, {
    //       method: "POST", // or 'PUT'
    //       headers: {
    //         "Content-Type": "application/json",
    //         Accept: "application/json",
    //       },
    //       body: JSON.stringify({
    //         demande_id: 2854,
    //         organisation_id: 1,
    //         url_redirect: "https://moitrader.com",
    //         temps: 15,
    //       }),
    //     })
    //       .then((res) => {
    //         if (!res.ok) {
    //           throw new Error(`HTTP error! Status: ${res.status}`);
    //         }
    //         return res.json();
    //       })
    //       .then(async (data) => {
    //         setTokenUser(data);
    //         // console.log("data", data);
    //         return await CheckoutGetUserInfo(data);
    //       });

    //     // console.log("Success", response);
    //     // return { response: response.json(), status: response.status };
    //   } catch (error) {
    //     console.error("Error:", error);
    //   }
    // };

    const CheckoutGetUserInfo = async (token) => {
      try {
        // https://api.datagreffe.ci/recouv/search
        fetch(
          `${process.env.REACT_APP_HOST}/api/org_token/paiement/token/${token}`,
          {
            method: "GET",
          }
        )
          .then((resUser) => {
            // console.log("resUser", resUser);
            if (!resUser.ok) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Désolé votre session est expiré!",
              });
              // throw new Error(`HTTP error! Status: ${resUser.status}`);
            }
            return resUser.json();
          })
          .then((data) => {
            // console.log("data", data);
            usePaiement.setMontant(data.decrypt.amount);
            setMoyen_paiements(data.decrypt);
          });

        // console.log("Success", response);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    CheckoutGetUserInfo(token);
    // CheckoutGenerateToken()
  }, [token]);

  // console.log("moyen_paiements", moyen_paiements);

  return (
    <div className="col-span-12 mt-8">
      <h2 className="intro-y text-lg font-medium">
        Faire un paiement pour la demande N°
        {demande.numero_demande}
      </h2>
      <div className="intro-y box py-10 sm:py-20 mt-">
        <Components.Payment
          moyen_paiements={moyen_paiements.moyen_paiements}
          currency={moyen_paiements.currency}
          handlePaymentClick={handlePaymentMethodClick}
          amount={usePaiement.montant}
        />
      </div>
      {isModalOpen &&
      (moyen_paiement.libelle.toLowerCase().includes("Stripe") ||
        moyen_paiement.libelle.toLowerCase().includes("carte")) ? (
        <Components.Modal
          handleModalClose={handleModalClose}
          title={"Paiement par Stripe"}
          handleModalValidate={null}
        >
          <Elements stripe={stripePromise} usePaiement={usePaiement}>
            <Components.Stripe.CheckoutForm
              usePaiement={usePaiement}
              handlePaymentSubmit={handlePaymentSubmit}
              currency={moyen_paiements.currency}
            />
          </Elements>
        </Components.Modal>
      ) : null}
    </div>
  );
}
