import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import logoImg from "../assets/images/logo-legafrik.png";
import * as Icons from "react-feather";
import { Utils } from "../utils";
import { TopBar } from "./TopBar";
import { Services } from "../services";

export function MobileMenu(props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const activeClassName = "menu--active";
  const dynamicClassName = ({ isActive }) => {
    return isActive ? `menu ${activeClassName}` : "menu";
  };

  return (
    <div className="mobile-menu md:hidden">
      <div className="mobile-menu-bar">
        <Link to="/" className="flex mr-auto">
          <img
            alt="Midone Tailwind HTML Admin Template"
            className="w-6"
            src={logoImg}
          />
        </Link>
        <button className="btn btn-link rounded" id="mobile-menu-toggler">
          <Icons.Menu
            className="w-8 h-8 text-white transform "
            onClick={() => Utils.Dom.toggleElement("#mobile-menu-dropdown")}
          />
        </button>
      </div>
      <ul className="border-t bg-theme-1 py-5 hidden" id="mobile-menu-dropdown">
        <li className="px-5 text-lg">
          <div className=" font-medium text-white ">
            {Utils.Auth.getUser()?.username ?? "Utilisateur"} <br />
            <div className=" text-xs text-theme-41 dark:text-gray-600">
              En ligne
            </div>
          </div>
        </li>
        <li>
          <Link to="/profil" className="menu">
            <div className="menu__icon">
              <Icons.User />
            </div>

            <div className="menu__title"> Profil </div>
          </Link>
        </li>
        <li>
          <Link to="/nouveau-mot-de-passe" className="menu">
            <div className="menu__icon">
              <Icons.Lock />
            </div>
            <div className="menu__title"> Mot de passe </div>
          </Link>
        </li>

        <li>
          <Link
            to="/"
            className={`menu ${pathname === "/" ? activeClassName : ""}`}
            onClick={() => Utils.Dom.toggleElement("#mobile-menu-dropdown")}
          >
            <div className="menu__icon">
              {" "}
              <Icons.Home />{" "}
            </div>
            <div className="menu__title"> Accueil </div>
          </Link>
        </li>
        <li>
          <NavLink
            to="/commandes"
            className={dynamicClassName}
            onClick={() => Utils.Dom.toggleElement("#mobile-menu-dropdown")}
          >
            <div className="menu__icon">
              {" "}
              <Icons.ShoppingCart />{" "}
            </div>
            <div className="menu__title"> Commandes </div>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/documents"
            className={dynamicClassName}
            onClick={() => Utils.Dom.toggleElement("#mobile-menu-dropdown")}
          >
            <div className="menu__icon">
              {" "}
              <Icons.Folder />{" "}
            </div>
            <div className="menu__title"> Documents </div>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/paramettres"
            className={dynamicClassName}
            onClick={() => Utils.Dom.toggleElement("#mobile-menu-dropdown")}
          >
            <div className="menu__icon">
              {" "}
              <Icons.Settings />{" "}
            </div>
            <div className="menu__title"> Paramètres </div>
          </NavLink>
        </li>
        <li>
          <div className=" box bg-theme-38 dark:bg-dark-6 text-white">
            <div className="p-2 border-t border-theme-40 dark:border-dark-3">
              <button
                className="flex items-center block p-2 transition duration-300 
                            ease-in-out bg-theme-1 hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
                style={{ width: "100%" }}
                onClick={() => Services.AuthService.logout(navigate)}
              >
                <Icons.LogIn className="w-4 h-4 mr-2" /> Deconnexion
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
