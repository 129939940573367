import { Fragment, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Components } from ".";
import * as Icons from "react-feather";
import { Utils } from "../utils";
import { Services } from "../services";
import Swal from "sweetalert2";

export function DemandeStepStatutaire(props) {
  // console.log("DemandeStep", props);
  const [choice, setChoice] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [showVille, setShowVille] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  const [isOther, setIsOther] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const abortController = useMemo(() => new AbortController(), []);
  const navigate = useNavigate();

  // console.log("props.champsDemandeData", props);
  // const dataUser = JSON.parse(window.localStorage.getItem("dataUser"));
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleCheck = (event) => {
    if (
      props.champsDemandeData.name === "email" &&
      !isValidEmail(event.target.value)
    ) {
      setError("Votre email n'est pas valable");
    } else {
      setError(null);
    }

    setEmail(event.target.value);
  };

  const handleInputChange = (name, value) => {
    // console.log("handleInputChange", name, value);
  };

  const handleNextClick = async (e) => {
    setIsModalOpen(false);

    if (props.champsDemandeData.name === "denomination") {
      let denomination = props.champsDemandeObject["denomination"];
      let denominationArray =
        denomination && denomination.length > 0 ? denomination : [];

      denominationArray.push(choice);
      props.handleNextClick(e, props.champsDemandeData.name, denominationArray);
      return;
    }
    setChoice(choice);

    props.handleNextClick(e, props.champsDemandeData.name, choice);
  };

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 p-8  ${
        props.step !== props.index ? "hidden" : ""
      }`}
    >
     <div className="intro-y col-span-12 ">
        <div className="font-me dium text-center text-lg text-weight-bold">
          {props.champsDemandeData.question ?? ""}
        </div>
        <div className="intro-y col-span-12 text-gray-600  mt-2 ">
          {props.champsDemandeData.description ?? ""}
        </div>
      </div>

      {/* {props.champsDemandeData.demande.includes('sci-civile')} */}
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Nom</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Nom"
          onChange={(e) => handleInputChange("nom", e.target.value)}
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Prénom(s)</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Prénom(s)"
          onChange={(e) => handleInputChange("prenoms", e.target.value)}
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Adresse mail</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="adresse mail"
          onChange={(e) => handleInputChange("adresse-mail", e.target.value)}
        />
      </div>

    
      <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Numéro de téléphone</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Numéro de téléphone"
                  onChange={(e) =>
                    handleInputChange( "numero-telephone", e.target.value)
                  }
                />
              </div>

      <div
        className="intro-y col-span-12 flex items-center justify-center
                        sm:justify-end mt-10"
      >
        {props.step > 1 ? (
          <button
            className="button w-24 justify-center block bg-gray-200 text-gray-600 
                                dark:bg-dark-1 dark:text-gray-300"
            onClick={props.handleBackClick ?? ""}
          >
            Revenir
          </button>
        ) : null}
        <button
          className="button w-24 justify-center block bg-theme-1 
                        text-white ml-2"
          onClick={handleNextClick}
        >
          {isDisabled ? "verification..." : "Suivant"}
        </button>
      </div>
    </div>
  );
}
