import { useEffect, useState } from "react"
import logoImg from '../assets/images/logo-legafrik.png';
import loginImg from '../assets/images/illustration.svg';
import { Components } from "../components";
import { AuthLayout } from "../layouts/AuthLayout";
import { Services } from "../services";

export function MailView(props){
    const abortController = new AbortController();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const handleMailSubmit = async (e) => {
        e.preventDefault();
        setErrorMessages([]);
        setIsDisabled(true);

        try {
            const payload = {
                email,
            }
            await Services.AuthService.mail(JSON.stringify(payload), 
            abortController.signal);
            setIsDisabled(false);
            setEmail('');
            alert(`Success! \nVeuillez consulter vos mail à l'addresse ${email} pour renouveler votre mot de passe`);
        } catch (error) {
            setIsDisabled(false);
            if ('messages' in error)
                error.messages.then(messages => setErrorMessages(messages[0]));
        }
    }

    useEffect(() => {
        window.document.body.className = 'login';

        return () => {
            window.document.body.className = 'app';
        }
    }, [])

    return (
        <AuthLayout>
            <div className="container sm:px-10">
                <div className="block xl:grid grid-cols-2 gap-4">
                    <div className="hidden xl:flex flex-col min-h-screen">
                        <span className="-intro-x flex items-center pt-5">
                            <img alt="Midone Tailwind HTML Admin Template" 
                            className="w-6" src={logoImg} />
                            <span className="text-white text-lg ml-3"> 
                                Leg<span className="font-medium">afrik</span> 
                            </span>
                        </span>
                        <div className="my-auto">
                            <img alt="Midone Tailwind HTML Admin Template" 
                            className="-intro-x w-1/2 -mt-16" src={loginImg} />
                            <div className="-intro-x text-white font-medium text-4xl leading-tight mt-10">
                                Gérez votre compte et suivez sereinement l'évolution de vos 
                                dossiers en temps réel, au même endroit !
                            </div>
                        </div>
                    </div>
                    <div className="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
                        <div className="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 
                        sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 
                        lg:w-2/4 xl:w-auto">
                            <Components.ErrorMessages>
                                {errorMessages}
                            </Components.ErrorMessages>
                            <Components.MailForm email={email} setEmail={setEmail}
                            password={password} isDisabled={isDisabled}
                            handleSubmit={handleMailSubmit}/>
                        </div>
                    </div>
                </div>
            </div>
        </AuthLayout>
    )
}