import { useEffect, useState } from "react";
import logoImg from "../assets/images/logo-legafrik.png";
import loginImg from "../assets/images/illustration.svg";
import { Components } from "../components";
import { AuthLayout } from "../layouts/AuthLayout";
import { Services } from "../services";
import { Utils } from "../utils";
// import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export function PasswordView(props) {
  const abortController = new AbortController();

  // const navigate = useNavigate("/");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [token, setToken] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const { id, email } = useParams();

  const [input, setInput] = useState({
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });

  const [isPassword, setIsPassword] = useState(false);
  const [isConfirmPassword, setIsConfirmPassword] = useState(false);

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          if (!value) {
            stateObj[name] = "Veuillez entrer le nouveau mot de passe";
          } else if (value.length < 6) {
            stateObj[name] =
              "Veuillez entrer un mot de passe supérieur à 6 charactères.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] =
              "Les mot de passe ne sont pas identique.";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Veuillez confirmer le nouveau mot de passe.";
          } else if (value.length < 6) {
            stateObj[name] =
              "Veuillez entrer un mot de passe supérieur à 6 charactères.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Les mots de passe ne sont pas identiques.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const changePassword = (e) => {
    const { name, value } = e.target;

    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "password") {
      setPassword(e.target.value);
    } else {
      setConfirmPassword(e.target.value);
    }

    validateInput(e);
  };

  const [errorMessages, setErrorMessages] = useState([]);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setErrorMessages([]);
    setIsDisabled(true);

    if (password === confirmPassword) {
      try {
        const payload = {
          password,
          confirmPassword,
          token: id,
        };

        const { data } = await Services.AuthService.password(
          JSON.stringify(payload),
          abortController.signal
        );

        Utils.Auth.setSessionToken(data.token);
        Utils.Auth.setUser(data.user);
        setIsDisabled(false);
        window.location.replace("/");
      } catch (error) {
        setIsDisabled(false);
        if ("messages" in error)
          error.messages.then((messages) => setErrorMessages(messages));
      }
    } else {
      setIsDisabled(false);
      if ("messages" in error)
        error.messages.then((messages) => setErrorMessages(messages));
    }
  };

  useEffect(() => {
    window.document.body.className = "login";

    return () => {
      window.document.body.className = "app";
    };
  }, []);

  return (
    <AuthLayout>
      <div className="container sm:px-10">
        <div className="block xl:grid grid-cols-2 gap-4">
          <div className="hidden xl:flex flex-col min-h-screen">
            <span className="-intro-x flex items-center pt-5">
              <img
                alt="Midone Tailwind HTML Admin Template"
                className="w-6"
                src={logoImg}
              />
              <span className="text-white text-lg ml-3">
                Leg<span className="font-medium">afrik</span>
              </span>
            </span>
            <div className="my-auto">
              <img
                alt="Midone Tailwind HTML Admin Template"
                className="-intro-x w-1/2 -mt-16"
                src={loginImg}
              />
              {/* <div className="-intro-x text-white font-medium text-4xl leading-tight mt-10">
                                Quelques clics de plus pour
                                <br />
                                créer votre compte.
                            </div> */}
              <div className="-intro-x mt-5 text-lg text-white dark:text-gray-500">
                Créez votre compte et accédez à votre espace client, téléchargez
                vos documents et suivez en temps réel l’évolution de votre
                dossier !
              </div>
            </div>
          </div>
          <div className="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
            <div
              className="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 
                        sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 
                        lg:w-2/4 xl:w-auto"
            >
              <Components.ErrorMessages>
                {errorMessages}
              </Components.ErrorMessages>
              <Components.PasswordForm
                changePassword={changePassword}
                input={input}
                error={error}
                Password={password}
                setPassword={setPassword}
                isPassword={isPassword}
                isConfirmPassword={isConfirmPassword}
                validateInput={validateInput}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                isDisabled={isDisabled}
                handleSubmit={handleLoginSubmit}
                email={email}
              />
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}
