import { useCallback, useEffect, useMemo, useState } from "react";
import { Hooks } from "../hooks"
import { useNavigate, useParams } from "react-router-dom";
import { Services } from "../services";
import { Utils } from "../utils";
import { Components } from "../components";

export function DemandePaiementView(props){
    const abortController = useMemo(() => new AbortController(), []);
    const navigate = useNavigate();
    const { id } = useParams()

    const useDemande = Hooks.useDemande();

    const [paiements, setPaiements] = useState([]);
    const [montant, setMontant] = useState();
    const [amount, setAmount] = useState('');
    const [remainingPayment, setRemainingPayment] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    const handlePaymentBtnClick = e => {
        e.preventDefault();
        setIsConfirmModalOpen(true);
    }

    const handleConfirmClick = () => {
        if (useDemande.pays.code !== 'CI') {
            navigate(`/demandes/${useDemande.id}/paiement?montant=${remainingPayment}`);
            return;
        }

        setIsConfirmModalOpen(false);
        setIsModalOpen(true);
    }

    const handleAmountValidate = e => {
        e.preventDefault();
        if (!amount || amount <= 1) return;
        navigate(`/demandes/${useDemande.id}/paiement?montant=${amount}`);
    }
 
    const initalize = useCallback(async () => {
        try {
            useDemande.setIsDisabled(true);
            const {demande} = await Services.DemandeService.getById(
                id, abortController.signal)
            const { paiements } = await Services.DemandeService.getDemandePaiements(
                id, abortController.signal);
                
            const totalPaiement = paiements.reduce((car, paiement) => 
            car += paiement.montant, 0);
            const montantTotal = demande.montant_total;

            setMontant(montantTotal);
            setRemainingPayment((montantTotal - totalPaiement) >= 1 ? 
            montantTotal - totalPaiement : 0);
            setPaiements(paiements);

            useDemande.fillDemande(demande);
            useDemande.setIsDisabled(false);
        } catch (error) {
            useDemande.setIsDisabled(false);
            if ('messages' in error) return;
                //Toast
        }
    }, [abortController, id])

    useEffect(() => {
        initalize();
    },[initalize])

    return (
        <div className="col-span-12">
            <div className="intro-y flex items-center mt-8">
                <h2 className="text-lg font-medium mr-auto">
                    Solde de la Commande
                </h2>
            </div>
            <div className='grid grid-cols-12 gap-6 mt-4'>
                <div className='col-span-12 lg:col-span-6'>
                    <div className="box p-5 zoom-in my-4">
                        <div className="flex items-center">
                            <div className="text-lg font-medium truncate">
                                Total à payer: {useDemande.montant_total ?? 0} {useDemande.pays?.monnaie}
                            </div>
                        </div>
                    </div>
                    <div className="box p-5 zoom-in">
                        <div className="flex items-center">
                            <div className="text-lg font-medium truncate text-theme-6">
                                Reste à payer: {remainingPayment} {useDemande.pays?.monnaie}
                            </div>
                        </div>
                    </div>
                    {remainingPayment && remainingPayment >  0? 
                        <div className="intro-y col-span-12 flex items-center justify-center
                            sm:justify-end mt-1">
                            <button className="button justify-center block bg-theme-1 
                            text-white ml-2" onClick={handlePaymentBtnClick}>
                                Faire un paiement
                            </button>
                        </div>
                    : null}
                </div>
                <div className='col-span-12 lg:col-span-6'>
                    <div className="report-timeline mt-5 relative">
                        {paiements.map((paiement, index) => {
                            return (
                                <div className="intro-x relative flex items-center mb-3" key={index}>
                                    <div className="box px-5 py-3 ml-4 flex-1 zoom-in">
                                        <div className="flex items-center">
                                            <div className="font-medium">
                                                {index === 0  ? "1er" : (index + 1) + "ème"} versement
                                            </div>
                                            <div className="text-sm text-gray-5 ml-auto text-theme-1">
                                                {paiement.montant} {useDemande.pays?.monnaie}
                                            </div>
                                        </div>
                                        <div className="text-gray-6 mt-1">
                                            Fait le {Utils.Date.styleDate(paiement.created_at, 'short')}&nbsp;
                                            par {paiement.moyen_paiement?.libelle}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            {isModalOpen ? 
                <Components.Modal handleModalClose={e => setIsModalOpen(false)}
                title={"Faire un paiement"} isControlVisible={true}
                handleModalValidate={handleAmountValidate} 
                isDisabled={useDemande.isDisabled}>
                    <div className="intro-y col-span-12">
                        <div className="mb-2">Montant à payer ({useDemande.pays?.monnaie})</div>
                        <input type='number' className="input w-full border flex-1" 
                        placeholder="Entrez le montant que vous souhaiter payer"
                        name="montant" id="montant" value={amount} 
                        onChange={e => setAmount(e.target.value)}
                        required disabled={useDemande.isDisabled}/>
                    </div>
                </Components.Modal>
            : null}
            {isConfirmModalOpen ?
                <Components.Modal handleModalClose={() => setIsConfirmModalOpen(false)}
                title={"Confirmation"} handleModalValidate={() => handleConfirmClick()}
                isControlVisible={true}>
                   Voulez-vous confirmer votre commande?
                </Components.Modal>
            : null}
        </div>
    )
}