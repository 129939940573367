import { useState } from "react";

export function NewPasswordForm(props) {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [check, setCheck] = useState(false);
  const [checkMessage, setCheckMessage] = useState("");

  const checkField = (e) => {
    if (!e.target.value || e.target.value === "") {
      setMessage("Veuillez remplir le champ!");
      setError(true);
    } else {
      setMessage("");
      setError(false);
    }
  };

  return (
    <form
      className="form"
      disabled={props.isDisabled ?? false}
      onSubmit={props.handleFormSubmit ?? null}
    >
      <div className="row">
        <div className="col-span-12 intro-y mb-3">
          <div className="form-group">
            <label htmlFor="password">Nouveau mot de passe</label>
            <input
              className="input w-full border flex-1 bg-white"
              type="password"
              id="password"
              name="password"
              placeholder="Nouveau mot de passe"
              value={props.password ?? ""}
              disabled={props.isDisabled}
              onChange={(e) => {
                checkField(e);
                props.setPassword(e.target.value);
              }}
              required
            />
            {error !== null ? <h2 style={{ color: "red" }}>{message}</h2> : ""}
          </div>
        </div>
        <div className="col-span-12 intro-y mb-3">
          <div className="form-group">
            <label htmlFor="password_confirmation">
              Confirmer le mot de passe
            </label>
            <input
              className="input w-full border flex-1 bg-white"
              type="password"
              id="password_confirmation"
              name="password_confirmation"
              placeholder="Confirmer le mot de passe"
              value={props.password_confirmation ?? ""}
              disabled={props.isDisabled}
              onChange={(e) => props.setPassword_confirmation(e.target.value)}
              required
            />

            {props.password_confirmation &&
            props.password !== props.password_confirmation ? (
              <h2 style={{ color: "red" }}>Mot de passe non identique!</h2>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="mt-4 flex justify-end intro-y">
          <button
            disabled={props.password !== props.password_confirmation}
            type="submit"
            className="button bg-theme-1 text-white px-5"
            onClick={props.handleFormSubmit}
          >
            {props.isDisabled ? "Chargement..." : "Enregistrer"}
          </button>
        </div>
      </div>
    </form>
  );
}
