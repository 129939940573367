import { useCallback, useEffect, useMemo, useState } from "react";
import { Components } from "../components";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Hooks } from "../hooks";
import { Services } from "../services";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CinetPaySerivce } from "../services/CinetpayService";

const stripePromise = loadStripe(process.env.REACT_APP_API_STRIPE_KEY);

export function PaiementView(props) {
  const abortController = useMemo(() => new AbortController(), []);
  const dataUser = JSON.parse(window.localStorage.getItem("dataUser"));
  const { id } = useParams();
  // console.log("PaiementView",props, "id", id);

  const navigate = useNavigate();
  const montant = useSearchParams()[0].get("montant");
  const usePaiement = Hooks.usePaiement();

  const [demande, setDemande] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [moyen_paiement, setMoyen_paiement] = useState({});
  const [moyen_paiements, setMoyen_paiements] = useState([]);

  const handlePaymentSubmit = async (e, payment_data) => {
    // console.log("payment_data", payment_data);
    // console.log("moyen_paiement", moyen_paiement);

    e.preventDefault();

    if (usePaiement.isDisabled) return;

    let payload = {
      type_paiement_id: 1,
      moyen_paiement_id: payment_data.id ?payment_data.id: moyen_paiement.id,
      demande_id: demande.id,
      montant: usePaiement.montant,
    };
    payment_data["pays"] = JSON.stringify(demande.pays);

    try {
      usePaiement.setIsDisabled(true);

      payload["payment_data"] = JSON.stringify(payment_data);
      payload["moyen_paiement_id"] = payment_data.id
      ? payment_data.id
      : moyen_paiement.id;
      await Services.PaiementService.create(
        JSON.stringify(payload),
        abortController.signal
      );

      // console.log("resultPaiment", resultPaiment);

      if (dataUser) {
        navigate("/new-passwords", {
          state: { token: `${dataUser.token}`, email: `${dataUser.email}` },
        });
      } else {
        navigate("/commandes");
      }
    } catch (error) {
      usePaiement.setIsDisabled(false);
      if ("messages" in error) return; //Toast
    }
  };

  const handlePaymentMethodClick = async (e, moyen_paiements) => {
    e.preventDefault();
    // console.log("moyen_paiement", moyen_paiements);
    setMoyen_paiement(moyen_paiements);
    setIsModalOpen(true);

    const payment_data = {
      id: moyen_paiement.id,
      moyen_paiement_id: moyen_paiements.id,
      currency: demande.pays.monnaie,
      description: "This is test payment",
    };

    let libelle;
    if (moyen_paiement?.libelle === "Payer plus tard") {
      libelle = 0;
    } else {
      libelle = usePaiement.montant;
    }

    const payload = {
      id: Math.round(Math.random() * 1000000).toString(),
      amount: libelle,
      payment_data: payment_data,
      moyen_paiement_id: moyen_paiements.id,
    };

    if (moyen_paiement.libelle?.includes("plus tard")) {
      await handlePaymentSubmit(e, payload);
    }

    if (moyen_paiement.libelle?.includes("Mobile")) {
      try {
        await new CinetPaySerivce().makePayment(payload);
        await handlePaymentSubmit(e, payload);
      } catch (error) {
        usePaiement.setIsDisabled(false);
        if ("messages" in error) return; //Toast
      }
    }
  };

  const handleModalClose = (e) => {
    setIsModalOpen(false);
    setMoyen_paiement("");
  };

  const initialize = useCallback(async () => {
    usePaiement.setIsDisabled(true);
    usePaiement.setMontant(montant);

    try {
      const { demande } = await Services.DemandeService.getById(
        id,
        abortController.signal
      );
      const { moyen_paiements } = await Services.MoyenPaiementService.getAll(
        abortController.signal
      );
      const moyen_paiementsCopy = moyen_paiements.filter(
        (moyen_paiement) =>
          !moyen_paiement.libelle.toLowerCase().includes("plus tard")
      );

      setDemande(demande);
      setMoyen_paiements(moyen_paiementsCopy);
    } catch (error) {
      if ("messages" in error) return;
      //toast
    } finally {
      usePaiement.setIsDisabled(false);
    }
  }, [abortController, id]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <div className="col-span-12 mt-8">
      <h2 className="intro-y text-lg font-medium">
        Faire un paiement pour la demande N°
        {demande.numero_demande}
      </h2>
      <div className="intro-y box py-10 sm:py-20 mt-">
        <Components.Payment
          moyen_paiements={moyen_paiements}
          currency={demande.pays?.monnaie}
          handlePaymentClick={handlePaymentMethodClick}
          amount={usePaiement.montant}
        />
      </div>
      {isModalOpen &&
      (moyen_paiement.libelle.toLowerCase().includes("Stripe") ||
        moyen_paiement.libelle.toLowerCase().includes("carte")) ? (
        <Components.Modal
          handleModalClose={handleModalClose}
          title={"Paiement par Stripe"}
          handleModalValidate={null}
        >
          <Elements stripe={stripePromise} usePaiement={usePaiement}>
            <Components.Stripe.CheckoutForm
              usePaiement={usePaiement}
              handlePaymentSubmit={handlePaymentSubmit}
              currency={demande.pays.monnaie}
            />
          </Elements>
        </Components.Modal>
      ) : null}
    </div>
  );
}
