import { useEffect, useState } from "react";
import { Utils } from "../../../utils";
import PhoneInput from "react-phone-input-2";

export function PresidentStep(props) {
  // console.log("props", props);
  const formContainer = "presidents-form-container";
  const associes = JSON.parse(props.associes);

  const [nombreGerant, setNombreGerant] = useState("");
  const [president, setPresident] = useState({});
  const [stated, setStated] = useState("celibataire");

  const [presidentType, setPresidentType] = useState("");
  Utils.Auth.getUser();

  const handleInputChange = (name, value) => {
    let presidentCopy = { ...president };
    if (value === "marie") {
      setStated("marie");
    }
    if (name === "associe-president") {
      presidentCopy = { "associe-president": "oui", ...JSON.parse(value) };
    } else {
      presidentCopy["type"] = "particulier";
      presidentCopy[name] = value;
    }

    setPresident(presidentCopy);
  };

  const handleTypePresidentChange = (value) => {
    setPresident({});

    const formContainerElem = window.document.querySelector(
      `#${formContainer}-0`
    );
    Array.from(formContainerElem.querySelectorAll(".field")).forEach(
      (field) => (field.value = "")
    );

    setPresidentType(value);
  };
  const handleStepSubmit = (e) => {
    e.preventDefault();

    if (!president) return alert("Vous devez remplir le formulaire !");
    president["fait-partie-des-associes"] = presidentType;
    props.handleNextClick(e, "presidents", JSON.stringify([president]));
  };

  useEffect(() => {
    if (!nombreGerant) return;
  }, [nombreGerant]);

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      <small className="intro-y col-span-12">
        Indiquez les informations relatives
        {props.demande === "commerciale" || props.demande === "civile"
          ? " à l'Administration"
          : " aux président"}
      </small>
      <div className="intro-y col-span-12" id={`${formContainer}-0`}>
        <div className={`mb-2 `}>
          {props.demande === "commerciale" || props.demande === "civile"
            ? "L'administration"
            : "Le président"}{" "}
          fait-il partie des associés ?
        </div>
        <div className="flex flex-col sm:flex-row mt-4 mb-2">
          <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
            <input
              type="radio"
              className="input border mr-2"
              id={`horizontal-radio-oui-0`}
              name={`president-0`}
              value="non"
              onChange={(e) => handleTypePresidentChange("oui")}
            />
            <label
              className="cursor-pointer select-none"
              htmlFor={`horizontal-radio-oui-0`}
            >
              Oui
            </label>
          </div>
          <div
            className="flex items-center text-gray-700 dark:text-gray-500 
                        mr-2 mt-2 sm:mt-0"
          >
            <input
              type="radio"
              className="input border mr-2"
              id={`horizontal-non-0`}
              name={`president-0`}
              value="oui"
              onChange={(e) => handleTypePresidentChange("non")}
            />
            <label
              className="cursor-pointer select-none"
              htmlFor={`horizontal-non-0`}
            >
              Non
            </label>
          </div>
        </div>
        <div
          className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                    ${
                      presidentType !== undefined && presidentType === "oui"
                        ? ""
                        : "hidden"
                    }`}
        >
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className="my-2">
              Lequel des associés est il
              {props.demande === "commerciale" || props.demande === "civile"
                ? " à l'administration"
                : " le président"}
              ?
            </div>
            <select
              type="text"
              className="input w-full border flex-1 field"
              onChange={(e) =>
                handleInputChange("associe-president", e.target.value)
              }
            >
              {associes.map((associe, index) => {
                return (
                  <option value={JSON.stringify(associe)} key={index}>
                    {Utils.Demande.getAssocieName(associe)}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className="mb-2">
              Nom & prénom du père{" "}
              {props.demande === "commerciale" || props.demande === "civile"
                ? "de l'administrateur"
                : "du président"}
            </div>
            <input
              type="text"
              className="input w-full border flex-1 field"
              placeholder="Nom"
              onChange={(e) => handleInputChange("nom-pere", e.target.value)}
            />
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">
                nom & prénom de la mère{" "}
                {props.demande === "commerciale" || props.demande === "civile"
                  ? "de l'administrateur"
                  : "du président"}
              </div>
              <input
                type="text"
                className="input w-full border flex-1 field"
                placeholder="Prenom"
                onChange={(e) => handleInputChange("nom-mere", e.target.value)}
              />
            </div>
          </div>
        </div>
        <div
          className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                    ${
                      presidentType !== undefined && presidentType === "non"
                        ? ""
                        : "hidden"
                    }`}
        >
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className="mb-2">
              Numéro de votre pièce d’identité{" "}
              <small>
                (les anciennes pièces ne sont pas prises en compte )
              </small>
            </div>
            <input
              type="text"
              className="input w-full border flex-1 field"
              placeholder=" Numéro de votre pièce d’identité"
              onChange={(e) =>
                handleInputChange("numero-piece-identite", e.target.value)
              }
            />
          </div>
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className="my-2">Type de la piece d'identité</div>
            <select
              type="text"
              className="input w-full border flex-1 field"
              onChange={(e) =>
                handleInputChange("type-piece-identite", e.target.value)
              }
            >
              <option value="CNI">CNI</option>
              <option value="Passeport">Passeport</option>
              <option value="Attestation d'identité">
                Attestation d'identité
              </option>
              <option value="Titre de séjour">Titre de séjour</option>
              <option value="Carte de résident">Carte de résident</option>
              <option value="Autres">Autres</option>
            </select>
          </div>
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className="my-2">Pays de délivrance de la pièce</div>
            <input
              type="text"
              className="input w-full border flex-1 field"
              placeholder="Pays de délivrance de la pièce"
              onChange={(e) =>
                handleInputChange("pays-piece-identite", e.target.value)
              }
            />
          </div>
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className="my-2">Date d’établissement de la pièce</div>
            <input
              type="date"
              className="input w-full border flex-1 field"
              placeholder="Date d’établissement de la pièce"
              onChange={(e) =>
                handleInputChange("date-piece-identite", e.target.value)
              }
            />
          </div>
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className="my-2">Date de validité de la pièce</div>
            <input
              type="date"
              className="input w-full border flex-1 field"
              placeholder="Date de validité de la pièce"
              onChange={(e) =>
                handleInputChange(
                  "date-fin-validite-piece-identite",
                  e.target.value
                )
              }
            />
          </div>
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className="my-2">Civilité</div>
            <select
              type="text"
              className="input w-full border flex-1 field"
              onChange={(e) => handleInputChange("civilite", e.target.value)}
            >
              <option value="monsieur">Monsieur</option>
              <option value="madame">Madame</option>
              <option value="mademoiselle">Mademoiselle</option>
            </select>
          </div>
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className="my-2">Nationalité</div>
            <input
              type="text"
              className="input w-full border flex-1 field"
              placeholder="Nationalité"
              onChange={(e) => handleInputChange("nationalite", e.target.value)}
            />
          </div>
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className="my-2">Adresse</div>
            <input
              type="text"
              className="input w-full border flex-1 field"
              placeholder="Adresse"
              onChange={(e) => handleInputChange("adresse", e.target.value)}
            />
          </div>
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className="mb-2">Nom</div>
            <input
              type="text"
              className="input w-full border flex-1 field"
              placeholder="Nom"
              onChange={(e) => handleInputChange("nom", e.target.value)}
            />
          </div>
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className="mb-2">Prenom</div>
            <input
              type="text"
              className="input w-full border flex-1 field"
              placeholder="Prenom"
              onChange={(e) => handleInputChange("prenoms", e.target.value)}
            />
          </div>
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className="mb-2">Numéro de téléphone (whatsapp) </div>
            {/* <input
              type="text"
              className="input w-full border flex-1 field"
              placeholder="Numéro de téléphone"
              onChange={(e) =>
                handleInputChange("numero-telephone", e.target.value)
              }
            /> */}
            <PhoneInput
              country={"ci"}
              // value={e.target.value
              onChange={(e) =>
                handleInputChange("numero-telephone", e)
              }
              autoFormat={false}
              inputStyle={{
                padding: "21px1 20px 21px 52px",
                width: "100%",
                boxShadow: "0px 3px 5px #00000007",
                borderColor: "rgba(226, 232, 240, 1)",
              }}
              placeholder="Numéro de téléphone"
            />
          </div>
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className="mb-2">Adresse mail </div>
            <input
              type="email"
              className="input w-full border flex-1 field"
              placeholder="adresse mail"
              onChange={(e) =>
                handleInputChange("adresse-mail", e.target.value)
              }
            />
          </div>
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className="mb-2">Date de naissance</div>
            <input
              type="date"
              className="input w-full border flex-1 field"
              placeholder="Date de naissance"
              onChange={(e) =>
                handleInputChange("date-naissance", e.target.value)
              }
            />
          </div>
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className="mb-2">Pays de naissance</div>
            <input
              type="text"
              className="input w-full border flex-1 field"
              placeholder="Pays de naissance"
              onChange={(e) =>
                handleInputChange("pays-naissance", e.target.value)
              }
            />
          </div>

          <div className="intro-y col-span-12 sm:col-span-6">
            <div className="mb-2">Lieu de naissance</div>
            <input
              type="text"
              className="input w-full border flex-1 field"
              placeholder="Lieu de naissance"
              onChange={(e) =>
                handleInputChange("lieu-naissance", e.target.value)
              }
            />
          </div>

          <div className="intro-y col-span-6 sm:col-span-6">
            {/* <div className="mb-2">Nationalité</div> */}
            <div className="mb-4">Situation matrimoniale</div>
            <div className="flex flex-col sm:flex-row mb-2 col-span-6">
              <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
                <input
                  type="radio"
                  className="input border mr-2 field"
                  id={`horizontal-radio-celibataire`}
                  name={`associe-situation-matrimoniale`}
                  value="celibataire"
                  onChange={(e) =>
                    handleInputChange(
                      "situation-matrimoniale",
                      "celibataire",
                      e.target.value
                    )
                  }
                />
                <label
                  className="cursor-pointer select-none"
                  htmlFor={`horizontal-radio-celibataire`}
                >
                  Célibataire
                </label>
              </div>
              <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
                <input
                  type="radio"
                  className="input border mr-2 field"
                  id={`horizontal-radio-marie`}
                  name={`associe-situation-matrimoniale`}
                  value="marie"
                  onChange={(e) =>
                    handleInputChange(
                      "situation-matrimoniale",
                      "marie",
                      e.target.value
                    )
                  }
                />
                <label
                  className="cursor-pointer select-none"
                  htmlFor={`horizontal-radio-marie`}
                >
                  Marié-e
                </label>
              </div>
            </div>
          </div>

          <div
            className={`intro-y col-span-6 ${
              stated !== undefined && stated === "marie" ? "" : "hidden"
            }`}
          >
            <div className="col-span-6  mb-4">Régime matrimonial </div>
            <div className="flex flex-col sm:flex-row mb-2 col-span-6">
              <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
                <input
                  type="radio"
                  className="input border mr-2 field"
                  id={`horizontal-radio-communaute-de-bien`}
                  name={`associe-regime-matrimonial`}
                  value="communaute-de-bien"
                  onChange={(e) =>
                    handleInputChange(
                      "regime-matrimonial",
                      "Communaute de bien",
                      e.target.value
                    )
                  }
                />
                <label
                  className="cursor-pointer select-none"
                  htmlFor={`horizontal-radio-communaute-de-bien`}
                >
                  Communauté de bien
                </label>
              </div>
              <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
                <input
                  type="radio"
                  className="input border mr-2 field"
                  id={`horizontal-radio-separation-de-bien`}
                  name={`associe-regime-matrimonial`}
                  value="separation-de-bien"
                  onChange={(e) =>
                    handleInputChange(
                      "regime-matrimonial",
                      "Séparation de bien",
                      e.target.value
                    )
                  }
                />
                <label
                  className="cursor-pointer select-none"
                  htmlFor={`horizontal-radio-separation-de-bien`}
                >
                  Séparation de bien
                </label>
              </div>
            </div>
          </div>

          <div className="intro-y col-span-12 sm:col-span-6">
            <div className="mb-2">
              Nom & Prénom(s) du père
              {props.demande === "commerciale" || props.demande === "civile"
                ? " de l'Administration"
                : " du président"}
            </div>
            <input
              type="text"
              className="input w-full border flex-1 field"
              placeholder="Nom & Prénom(s) du père"
              onChange={(e) => handleInputChange("nom-pere", e.target.value)}
            />
          </div>
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className="mb-2">
              Nom & Prénom(s) de la mère{" "}
              {props.demande === "commerciale" || props.demande === "civile"
                ? " de l'Administration"
                : " du président"}
            </div>
            <input
              type="text"
              className="input w-full border flex-1 field"
              placeholder="Nom & Prénom(s) de la mère"
              onChange={(e) => handleInputChange("nom-mere", e.target.value)}
            />
          </div>
        </div>
      </div>

      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
