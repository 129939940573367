import { useCallback, useEffect, useMemo, useState } from "react";
import { Services } from "../services";
import { Components } from ".";
import { Utils } from "../utils";

export function DocumentFormaliteStep(props) {
    const abortController = useMemo(() => new AbortController(), []);
    const tableActions = ['download'];
    const tableAttributes = {
        "type de document": {},
        "derniere mise a jour": {},
    };

    const [documents, setDocuments] = useState([]);
    const [documentTableData, setDocumentTableData] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    const initialize = useCallback(async () => {
        try {
            const {documents} = await Services.DemandeService.getDocuments(
                props.useDemande.id, abortController.signal, props.etapeTraitement.id);
            const documentTableDataCopy = documents.map((document) => {
                return {
                    "type de document": document.type_document.libelle,
                    "derniere mise a jour": Utils.Date.styleDate(
                        document.updated_at, 'long'),
                    url: document.document_url
                };
            });

            setDocuments(documents);
            //console.log("documents",documents);
            setDocumentTableData(documentTableDataCopy);
            setIsDisabled(false);
        } catch (error) {
            if ('messages' in error) return;
                //Toaster
        }
    }, [abortController])

    useEffect(() => {
        initialize();
    }, [initialize])

    return (
        <div className="grid grid-cols-12 mt-5">
            <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
                <Components.Table tableAttributes={tableAttributes}
                        tableData={documentTableData} tableActions={tableActions}
                        controllers={{handleEditClick:null, handleReadClick: null, 
                        handleDeleteClick:null}} />
            </div>
            <div className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10">
                <button className="button justify-center block bg-theme-1 
                text-white ml-2 px-6" onClick={props.handleSubmit} 
                disabled={props.useDemande.isDisabled}>
                    {props.useDemande.isDisabled ? "Chargement..." : "Suivant"}
                </button>
            </div>
        </div>        
    )
}