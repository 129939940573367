import { Utils } from "../utils";
import { Auth } from "../utils/Auth";

const HOST = "http://127.0.0.1:5000";
const PORT = "8000";
const URL = process.env.REACT_APP_HOST ?? `${HOST}:${PORT}`;

const ROOT_PATH = "/api";


const HEADERS = new Headers({
  "Content-type": "application/json",
  Accept: "application/json",
  Connection: "keep-alive",
  Authorization: `Bearer ${Utils.Auth.getSessionToken()}`,
});

const HEADERSS = new Headers({
  "Content-type": "application/json",
  Accept: "application/json",
  Connection: "keep-alive",
});

// const dataUser = JSON.parse(window.localStorage.getItem("dataUser"));

// const HEADERSOFFLINE = new Headers({
//   "Content-type": "application/json",
//   Accept: "application/json",
//   Connection: "keep-alive",
//   Authorization: `Bearer ${dataUser?.token}`,
// });

const FORMDATA_HEADERS = new Headers({
  Accept: "application/json",
  Authorization: `Bearer ${Utils.Auth.getSessionToken()}`,
});

const get = (endpoint, signal = new AbortController().signal) => {
  return new Promise((resolve, reject) => {
    fetch(`${URL}${ROOT_PATH}/${endpoint}`, {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Connection: "keep-alive",
        Authorization: `Bearer ${Utils.Auth.getSessionToken()}`,
      },
      signal,
    })
      .then((response) => {
        if (!response.ok) {
          return reject({
            status: response.status,
            messages: getResponseErrors(response),
          });
        }

        return response.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => reject(error));
  });
};

const gets = (endpoint, signal = new AbortController().signal) => {
  return new Promise((resolve, reject) => {
    fetch(`${URL}${ROOT_PATH}/${endpoint}`, {
      headers: HEADERSS,
      signal,
    })
      .then((response) => {
        if (!response.ok) {
          return reject({
            status: response.status,
            messages: getResponseErrors(response),
          });
        }

        return response.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => reject(error));
  });
};

const post = (
  endpoint,
  payload = "",
  signal = new AbortController().signal
) => {
  // const header =
  //   HEADERS !== null && HEADERS !== undefined ? HEADERS : HEADERSOFFLINE;
  return new Promise((resolve, reject) => {
    fetch(`${URL}${ROOT_PATH}/${endpoint}`, {
      method: "post",
      headers: HEADERS,
      body: payload,
      signal,
    })
      .then((response) => {
        if (!response.ok) {
          return reject({
            status: response.status,
            messages: getResponseErrors(response),
          });
        }

        return response.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => reject(error));
  });
};

const posts = (
  endpoint,
  token,
  payload = "",
  signal = new AbortController().signal
) => {
  return new Promise((resolve, reject) => {
    fetch(`${URL}${ROOT_PATH}/${endpoint}`, {
      method: "post",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Connection: "keep-alive",
        Authorization: `Bearer ${token}`,
      },
      body: payload,
      signal,
    })
      .then((response) => {
        if (!response.ok) {
          return reject({
            status: response.status,
            messages: getResponseErrors(response),
          });
        }

        return response.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => reject(error));
  });
};
const postFormData = (
  endpoint,
  payload = "",
  signal = new AbortController().signal
) => {
  return new Promise((resolve, reject) => {
    fetch(`${URL}${ROOT_PATH}/${endpoint}`, {
      method: "post",
      headers: FORMDATA_HEADERS,
      body: payload,
      signal,
    })
      .then((response) => {
        if (!response.ok) {
          return reject({
            status: response.status,
            messages: getResponseErrors(response),
          });
        }

        return response.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => reject(error));
  });
};

const put = (endpoint, payload = "", signal = new AbortController().signal) => {
  return new Promise((resolve, reject) => {
    fetch(`${URL}${ROOT_PATH}/${endpoint}`, {
      method: "put",
      headers: HEADERS,
      body: payload,
      signal,
    })
      .then((response) => {
        if (!response.ok) {
          return reject({
            status: response.status,
            messages: getResponseErrors(response),
          });
        }

        return response.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => reject(error));
  });
};

const erase = (endpoint, signal = new AbortController().signal) => {
  return new Promise((resolve, reject) => {
    fetch(`${URL}${ROOT_PATH}/${endpoint}`, {
      method: "delete",
      headers: HEADERS,
      signal,
    })
      .then((response) => {
        if (!response.ok) {
          return reject({
            status: response.status,
            messages: getResponseErrors(response),
          });
        }

        return response.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => reject(error));
  });
};

const getResponseErrors = (response) => {
  return new Promise((resolve, reject) => {
    if (!response) reject(null);
    if (response.status === 401) {
      Auth.removeSessionToken();
      // window.location.replace('/connexion');
    }

    response.json().then((result) => {
      let errorMessages = [];

      errorMessages.push(result.message);

      for (let field in result) errorMessages.push(`${field} ${result[field]}`);

      resolve(errorMessages);
    });
  });
};

export const Api = {
  gets,
  get,
  post,
  posts,
  put,
  erase,
  postFormData,
};
