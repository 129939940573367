import { useCallback, useEffect, useState } from "react";
import * as Icons from "react-feather";
import Alert from "react-bootstrap/Alert";

export function RecapSuivi(props) {
  // console.log("Cart", props);

  return (
    <>
      <div className="pos intro-y  gap-5 mt-5 px-5">
        <div className="font-medium text-center text-lg">
        Votre information a été enregistrée avec succès ! <br/> Cliquez sur le bouton <b> 'Prochaine étape' </b> pour passer à l'étape suivante et compléter votre formulaire.
        </div>

        <div className="intro-y grid grid-cols-12 gap-4">
          {/* <button
              className="button w-32 border border-gray-400 dark:border-dark-5 
                        text-gray-600 dark:text-gray-300"
              onClick={props.handleBackClick}
            >
              Payer
            </button> */}
          <button
            className="button button--lg  mt-8  border  border-gray-400 col-span-12 sm:col-span-1"
            onClick={props.handleBackClick}
          >
            Revenir
          </button>
          <button
            className="button button--lg flex  block text-white justify-center bg-theme-1 
              mt-8  sm:w-40 col-span-12 sm:col-span-1"
            onClick={props.handleValidateClick}
          >
            {props.isDisabled ? "En cours..." : "Prochaine étape"}
            <Icons.ArrowRightCircle className="ml-1"/>
          </button>
        </div>
      </div>
    </>
  );
}
