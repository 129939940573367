import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Icons from "react-feather";
import { Link } from "react-router-dom";

export function PasswordForm(props) {
  const navigate = useNavigate();
  //console.log("navigate",navigate);

  // Initialize a boolean state
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);

  // Password toggle handler
  const togglePassword1 = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown1(!passwordShown1);
  };

  const togglePassword2 = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown2(!passwordShown2);
  };

  return (
    <form name="loginForm" id="loginForm" onSubmit={props.handleSubmit}>
      <h2 className="intro-x font-bold  text-center">
        Créez votre compte {props.email} !
      </h2>
      <div className="intro-x mt-2 text-gray-500 xl:hidden text-center">
        Créez votre compte et accédez à votre espace client, téléchargez vos
        documents et suivez en temps réel l’évolution de votre dossier !
      </div>

      <div className="relative intro-x mt-8">
        <input
          type={passwordShown1 ? "text" : "password"}
          className="input pr-12 w-full border col-span-4"
          placeholder="Nouveau mot de passe"
          id="password"
          name="password"
          value={props.password}
          onChange={props.changePassword}
          disabled={props.isDisabled}
          onBlur={props.validateInput}
          required
        />
        <div
          onClick={togglePassword1}
          class="absolute top-0 right-0 rounded-r w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
        >
          {togglePassword1 ? <Icons.Eye /> : <Icons.EyeOff />}
        </div>
      </div>

      <div>
        {" "}
        {props.error.password && (
          <span className="err text-red-700">{props.error.password}</span>
        )}
      </div>

      <div className="relative intro-x mt-8">
        <input
          type={passwordShown2 ? "text" : "password"}
          className="input pr-12 w-full border col-span-4"
          placeholder="Confirmer mot de passe"
          id="confirmPassword"
          name="confirmPassword"
          value={props.confirmPassword}
          onChange={props.changePassword}
          disabled={props.isDisabled}
          onBlur={props.validateInput}
          required
        />
        <div
          onClick={togglePassword2}
          class="absolute top-0 right-0 rounded-r w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
        >
          {togglePassword2 ? <Icons.Eye /> : <Icons.CloudDrizzle />}
        </div>
      </div>

      {props.error.confirmPassword && (
        <span className="err text-red-700">{props.error.confirmPassword}</span>
      )}
      {/* <div className="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4">
                <div className="flex items-center mr-auto">
                    <input type="checkbox" className="input border mr-2" id="remember-me" />
                    <label className="cursor-pointer select-none" htmlFor="remember-me">
                        Se souvenir de moi
                    </label>
                </div>
                <Link to="/">Mot de passe oublié?</Link>  
            </div> */}
      <div className="intro-x mt-5 xl:mt-8 text-center xl:text-left">
        <button
          className="button button--lg w-full xl:w-32 text-white 
                bg-theme-1 xl:mr-3 align-top"
          type="submit"
          onClick={props.handleSubmit}
          disabled={props.isDisabled}
        >
          {props.isDisabled ? "Chargement" : "Connexion"}
        </button>
        {/* <button className="button button--lg w-full xl:w-40 text-gray-700 
                border border-gray-300 dark:border-dark-5 dark:text-gray-300 mt-3 xl:mt-0 align-top"
                disabled={props.isDisabled} onClick={() => navigate('/inscription')}>
                    Inscrivez-vous
                </button> */}
      </div>
      <div className="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left">
        En vous connectant, vous acceptez nos
        <br />
        <Link className="text-theme-1 dark:text-theme-10" to="/">
          Conditions d'uilisation
        </Link>{" "}
        &{" "}
        <Link className="text-theme-1 dark:text-theme-10" href="/">
          Politique de confidentialité
        </Link>
      </div>
    </form>
  );
}
