import { Fragment, useEffect, useState } from "react";
import { Utils } from "../../../../../utils";
import { Alert } from "bootstrap";
import { Accordion } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";

export function InfoModification(props) {
  // console.log("props", props);
  const associesPart = props.nombreAssocies || JSON.parse(props.associes);
  const associesPartVendeur =
    JSON.parse(props.chamDemande.associes) ?? JSON.parse(props.associes);
  // console.log("associesPartVendeur", associesPartVendeur);

  const [statePartAutorise, setStatePartAutorise] = useState(false);
  const [nombreAssociesAutorise, setNombreAssociesAutorise] = useState(0);
  const [nombreAssocies, setNombreAssocies] = useState(1);
  const [nombreAssociesPart, setNombreAssociesPart] = useState(1);
  const [associesParts, setAssociesParts] = useState([]);
  const [associesAutorise, setAssociesAutorise] = useState([]);
  const [associes, setAssocies] = useState([]);
  const [datas, setDatas] = useState([]);

  const [stateStatusGerant, setStateStatusGerant] = useState(false);

  let result;
  if (props.chamDemande["modification-du-statut"]) {
    let tab = props.chamDemande["modification-du-statut"];
    result = Object.entries(tab).map(([key, value]) => ({ key, value }));
  }

  const handleStatePart = (value) => {
    if (value === "oui") {
      setStatePartAutorise(true);
    } else {
      setAssociesAutorise(null);
      setNombreAssocies(0);
      setStatePartAutorise(false);
    }
  };

  const handleTypeAssocieChange = (index, value) => {
    // console.log("index, value", index, value);
    const partAssocie = associesAutorise.map((associe) => {
      return { ...associe };
    });

    if (value === "societe") {
      partAssocie[index] = { ...partAssocie[index], type: "societe" };
    }

    if (value === "particulier") {
      partAssocie[index] = { ...partAssocie[index], type: "particulier" };
    }

    // associesAutorise(partAssocie);
    setAssociesAutorise(partAssocie);
    // setAssocies(partAssocie);
  };

  const handleTypeGerantChange = (index, value) => {
    // console.log("handleTypeGerantChange", index, value);
    const associeCopy = associesParts.map((associe) => {
      return { ...associe };
    });

    if (value === "oui") {
      associeCopy[index] = { "associe-part": "oui" };
    }
    if (value === "non") {
      associeCopy[index] = { "associe-part": "non" };
    }
    setAssociesParts(associeCopy);
  };

  const handleTypeGerant = (index, value) => {
    //console.log("handleTypeGerant", index, value);
    const gerantsCopy = associesParts.map((associe) => {
      return { ...associe };
    });

    // console.log("gerantsCopy", gerantsCopy);

    if (value === "societe") {
      gerantsCopy[index] = { ...gerantsCopy[index], type: "societe" };
    }

    if (value === "particulier") {
      gerantsCopy[index] = { ...gerantsCopy[index], type: "particulier" };
    }
    if (value !== "particulier" && value !== "societe") {
      gerantsCopy[index] = { ...gerantsCopy[index], value };
    }
    setAssociesParts(gerantsCopy);

    // setAssocies(gerantsCopy);
  };

  const handleInputGerantChange = (name, value) => {
    // console.log("index, name, value", index, name, value);

    if (name === "status-gerant" && value === "Oui") {
      setStateStatusGerant(true);
    }
    if (name === "status-gerant" && value === "Non") {
      setStateStatusGerant(false);
    }
    const associesCopy = { ...datas };

    // if (!index) {
    associesCopy[name] = value;
    // }
    // if (index === 0 || index) {
    //   associesCopy[index][name] = value;
    // }
    // setDatas(associesCopy);
    // setDatas(associesCopy);
    setDatas(associesCopy);
  };
  const handleInputChange = (index, name, value) => {
    const associesCopy = [...associesAutorise];

    // console.log("appoteur", index, name, value);

    associesCopy[index][name] = value;
    // if (index === 0 || index) {

    // }
    // setDatas(associesCopy);
    // setDatas(associesCopy);
    datas["appoteur"] = associesCopy;
    // setAssocies(associesCopy);
    // console.log("associes ", associes);
  };
  // console.log("setDatas ", datas);

  const handleInputAssociePartChange = (index, name, value) => {
    console.log("handleInputAssociePartChange", index, name, value);
    console.log("associesPartVendeur", associesPartVendeur[index]);
    // setMontantPart(index);
    // if (name === "vendeur") {
    //   const pourcentage = associesPartVendeur.filter(
    //     (item, index) => item["nom-prenom-vendeur"] === value
    //   );
    //   // setPourcentagePart(pourcentage);
    // }
    const associesCopy = [...associesParts];

    associesCopy[index][name] = value;

    // setDatas(associesCopy);
    // setDatas(associesCopy);
    // console.log("associesCopy==> ", associesCopy);
    datas["AssociesParts"] = associesCopy;
    // setAssociesParts(associesCopy);
  };

  // console.log("AssociesParts", associesParts);
  // const handleTypePartAssocie = (index, value) => {
  //   const gerantsCopy = associes.map((associe) => {
  //     return { ...associe };
  //   });

  //   if (value === "societe") {
  //     gerantsCopy[index] = { ...gerantsCopy[index], type: "societe" };
  //   }

  //   if (value === "particulier") {
  //     gerantsCopy[index] = { ...gerantsCopy[index], type: "particulier" };
  //   }

  //   setAssocies(gerantsCopy);
  // };

  // console.log("datas", datas);

  const handleStepSubmit = (e) => {
    e.preventDefault();
    // let tab = [];
    // tab.push(...associes, ...associesAutorise, ...associesParts, ...datas);
    // console.log("associes", tab);
    props.handleNextClick(e, "parts-des-associe", JSON.stringify(datas));
  };

  useEffect(() => {
    if (!nombreAssocies) return;
    if (nombreAssocies && nombreAssocies <= 50) {
      setAssocies(Array(parseInt(nombreAssocies)).fill({}));
    }
  }, [nombreAssocies]);

  useEffect(() => {
    if (!nombreAssociesAutorise) return;
    if (nombreAssociesAutorise && nombreAssociesAutorise <= 50) {
      setAssociesAutorise(Array(parseInt(nombreAssociesAutorise)).fill({}));
    }
  }, [nombreAssociesAutorise]);

  useEffect(() => {
    if (!nombreAssociesPart) return;
    if (nombreAssociesPart && nombreAssociesPart <= 50) {
      setAssociesParts(Array(parseInt(nombreAssociesPart)).fill({}));
    }
  }, [nombreAssociesPart]);
  // console.log("associesPartVendeur", associesPartVendeur[montantPart]);

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      <h4 className="intro-y col-span-12 text-lg leading-none">
        <strong>Informations relatives aux modifications à apporter</strong>
      </h4>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2 ">
          Quelle est la valeur nominale d’une part de votre société (voir les
          statuts actuels)
        </div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="La valeur nominale d’une part"
          onChange={(e) =>
            handleInputGerantChange("valeur-nominale", e.target.value)
          }
        />
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2 ">
          Quelle est la date de création de votre société (Voir le registre de
          commerce)
        </div>
        <input
          type="date"
          className="input w-full border flex-1 field"
          placeholder="La date de création"
          onChange={(e) =>
            handleInputGerantChange("date-creation", e.target.value)
          }
        />
      </div>

      {result?.map((val, idx) => {
        return (
          <Fragment key={idx}>
            <div className="intro-y col-span-12">
              {/* {val.value.includes("modifie mes activités") ? (
                <div className="intro-y col-span-12 sm:col-span-6">
                  <div className="intro-y col-span-12 sm:col-span-12 text-lg">
                    Quel est le nouvel objet social de la société ?
                  </div>
                  <span className="intro-y col-span-12 sm:col-span-12">
                    Changement de dénomination
                  </span>
                  <span className="mb-2">
                    Indiquez le nouveau nom de la Société
                  </span>
                  <input
                    type="text"
                    className="input w-full border flex-1 field"
                    placeholder="Ex : Nickitech"
                    onChange={(e) =>
                      handleInputChange(
                        null,
                        "changement-denomination",
                        e.target.value
                      )
                    }
                  />
                </div>
              ) : null} */}

              {/* {val.value.includes("transfère mon siège social") ? (
                <div className="intro-y col-span-12 sm:col-span-6">
                  <div className="intro-y col-span-12 sm:col-span-12">
                    Transfert de siège social
                  </div>
                  <div className="mb-2">
                    Indiquez le nouveau siège de la société
                  </div>
                  <textarea
                    placeholder="Ex : Abidjan Cocody Les II Plateaux 7ème tranche"
                    className={`input w-full border flex-1 field`}
                    rows="5"
                    cols="33"
                    onChange={(e) =>
                      handleInputChange("transfer-siege", e.target.value)
                    }
                  />
                </div>
              ) : null} */}

              {val.value.includes("un nouveau Gérant/Président") ? (
                <div className="grid grid-cols-12 gap-4 row-gap-5 col-span-12">
                  <div className="intro-y col-span-12 ">
                    <hr />
                  </div>
                  <div className="intro-y col-span-12 ">
                    Le gérant remplacé est-il mentionné dans les statuts ?
                  </div>
                  <div className="intro-y flex col-span-12 sm:col-span-6 ">
                    <div className="intro-y flex col-span-12 sm:col-span-6 mr-8">
                      <input
                        type="radio"
                        className="input border mr-2 field"
                        id={`horizontal-radio-status-oui-0`}
                        name={`status-gerant`}
                        value="Oui"
                        onChange={(e) =>
                          handleInputGerantChange("status-gerant", "Oui")
                        }
                      />
                      <label
                        className="cursor-pointer select-none"
                        htmlFor={`horizontal-radio-status-oui-0`}
                      >
                        Oui
                      </label>
                    </div>
                    <div className="intro-y flex col-span-12 sm:col-span-6 ">
                      <input
                        type="radio"
                        className="input border mr-2 field"
                        id={`horizontal-radio-status-non-0`}
                        name={`status-gerant`}
                        value="marie"
                        onChange={(e) =>
                          handleInputGerantChange("status-gerant", "Non")
                        }
                      />
                      <label
                        className="cursor-pointer select-none"
                        htmlFor={`horizontal-radio-status-non-0`}
                      >
                        Non
                      </label>
                    </div>
                  </div>
                  {stateStatusGerant ? (
                    <>
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Civilité</div>
                        <select
                          type="text"
                          className="input w-full border flex-1 field"
                          onChange={(e) =>
                            handleInputGerantChange(
                              "civilite-nouveau-gerant",
                              e.target.value
                            )
                          }
                        >
                          <option value="">
                            Veuillez selectionner la civilité ?
                          </option>
                          <option value="monsieur">Monsieur</option>
                          <option value="madame">Madame</option>
                          <option value="mademoiselle">Mademoiselle</option>
                        </select>
                      </div>

                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2 ">Nom du gérant</div>
                        <input
                          type="text"
                          className="input w-full border flex-1 field"
                          placeholder="Nom du gérant"
                          onChange={(e) =>
                            handleInputGerantChange(
                              "nom-nouveau-gerant",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2 ">Prénom(s) du gérant</div>
                        <input
                          type="text"
                          className="input w-full border flex-1 field"
                          placeholder="Prénom(s) du gérant"
                          onChange={(e) =>
                            handleInputGerantChange(
                              "prenoms-nouveau-gerant",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Contact (whatsapp)</div>
                        {/* <input
                          type="text"
                          className="input w-full border flex-1 field"
                          placeholder="Contact (whatsapp)"
                          onChange={(e) =>
                            handleInputGerantChange("telephone", e.target.value)
                          }
                        /> */}
                        <PhoneInput
                          country={"ci"}
                          // value={e.target.value
                          onChange={(e) =>
                            handleInputGerantChange("numero-telephone", e)
                          }
                          autoFormat={false}
                          inputStyle={{
                            padding: "21px1 20px 21px 52px",
                            width: "100%",
                            boxShadow: "0px 3px 5px #00000007",
                            borderColor: "rgba(226, 232, 240, 1)",
                          }}
                          placeholder="Numéro de téléphone"
                        />
                      </div>

                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="my-2">Email </div>
                        <input
                          type="text"
                          className="input w-full border flex-1 field"
                          placeholder="Email"
                          onChange={(e) =>
                            handleInputGerantChange(
                              "adresse-mail",
                              e.target.value
                            )
                          }
                        />
                      </div>

                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="my-2">Adresse</div>
                        <input
                          type="text"
                          className="input w-full border flex-1 field"
                          placeholder="Adresse"
                          onChange={(e) =>
                            handleInputGerantChange(
                              "adresse-nouveau-gerant",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Date de naissance</div>
                        <input
                          type="date"
                          className="input w-full border flex-1 field"
                          placeholder="Date de naissance"
                          onChange={(e) =>
                            handleInputGerantChange(
                              "date-naissance-nouveau-gerant",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Lieu de naissance</div>
                        <input
                          type="text"
                          className="input w-full border flex-1 field"
                          placeholder="Lieu de naissance"
                          onChange={(e) =>
                            handleInputGerantChange(
                              "lieu-naissance-nouveau-gerant",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Nationalite</div>
                        <input
                          type="text"
                          className="input w-full border flex-1 field"
                          placeholder="Nationalite"
                          onChange={(e) =>
                            handleInputGerantChange(
                              "nationalite-nouveau-gerant",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="my-2">Type de la piece d'identité</div>
                        <select
                          type="text"
                          className="input w-full border flex-1 field"
                          onChange={(e) =>
                            handleInputGerantChange(
                              "type-piece-identite",
                              e.target.value
                            )
                          }
                        >
                          <option value="CNI">CNI</option>
                          <option value="Passeport">Passeport</option>
                          <option value="Attestation d'identité">
                            Attestation d'identité
                          </option>
                          <option value="Titre de séjour">
                            Titre de séjour
                          </option>
                          <option value="Carte de résident">
                            Carte de résident
                          </option>
                          <option value="Autres">Autres</option>
                        </select>
                      </div>
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">
                          Numéro de votre pièce d’identité{" "}
                          <small>
                            (les anciennes pièces ne sont pas prises en compte )
                          </small>
                        </div>
                        <input
                          type="text"
                          className="input w-full border flex-1 field"
                          placeholder=" Numéro de votre pièce d’identité"
                          onChange={(e) =>
                            handleInputGerantChange(
                              "numero-piece-identite",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="my-2">
                          Date d’établissement de la pièce
                        </div>
                        <input
                          type="date"
                          className="input w-full border flex-1 field"
                          placeholder="Date d’établissement de la pièce"
                          onChange={(e) =>
                            handleInputGerantChange(
                              "date-piece-identite",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="my-2">Date de validité de la pièce</div>
                        <input
                          type="date"
                          className="input w-full border flex-1 field"
                          placeholder="Date de validité de la pièce"
                          onChange={(e) =>
                            handleInputGerantChange(
                              "date-fin-validite-piece-identite",
                              e.target.value
                            )
                          }
                        />
                      </div>

                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Nom & Prénom(s) du père </div>
                        <input
                          type="text"
                          className="input w-full border flex-1 field"
                          placeholder="Nom & Prénom(s) du père"
                          onChange={(e) =>
                            handleInputGerantChange("nom-pere", e.target.value)
                          }
                        />
                      </div>
                      <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">Nom & Prénom(s) de la mère</div>
                        <input
                          type="text"
                          className="input w-full border flex-1 field"
                          placeholder="Nom & Prénom(s) de la mère"
                          onChange={(e) =>
                            handleInputGerantChange("nom-mere", e.target.value)
                          }
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              ) : null}
            </div>

            {val.value.includes("cède des parts") ? (
              <div className="grid grid-cols-12 gap-4 row-gap-5 col-span-12">
                <div className="intro-y col-span-12 text-lg ">
                  <strong>
                    Indiquez le nombre d’acheteurs de parts sociales
                  </strong>
                </div>

                <div className="intro-y col-span-12 sm:col-span-6">
                  {/* <div className="mb-2 ">
                    Combien d’actionnaires aimeraient vendre des parts ?
                  </div> */}
                  <input
                    type="number"
                    className="input w-full border flex-1 field"
                    placeholder="Nombre actionnaire"
                    onChange={(e) => setNombreAssociesPart(e.target.value)}
                  />
                </div>
                {nombreAssocies && nombreAssocies > 50 ? (
                  <div className="intro-y col-span-12 text-center ">
                    <Alert variant="danger">
                      Le nombre d'associés ne doit pas dépasser 50 personnes !
                    </Alert>
                  </div>
                ) : null}

                {associesParts.map((associe, index) => {
                  return (
                    <div className="intro-y col-span-12" key={index}>
                      <div className="mb-2 text-md">
                        L’acheteur des parts sociales (numéro {index + 1}) fait
                        partie des Associés actuels ?
                      </div>
                      <div className="intro-y flex col-span-12 my-5">
                        <div className="intro-y flex col-span-12 sm:col-span-6 mr-8">
                          <input
                            type="radio"
                            className="input border mr-2"
                            id={`horizontal-radio-oui-${index}`}
                            name={`associe-${index}`}
                            value="non"
                            onChange={(e) =>
                              handleTypeGerantChange(index, "oui")
                            }
                          />
                          <label
                            className="cursor-pointer select-none"
                            htmlFor={`horizontal-radio-oui-${index}`}
                          >
                            Oui
                          </label>
                        </div>
                        <div className="intro-y flex col-span-12 sm:col-span-6">
                          <input
                            type="radio"
                            className="input border mr-2"
                            id={`horizontal-non-${index}`}
                            name={`associe-${index}`}
                            value="oui"
                            onChange={(e) =>
                              handleTypeGerantChange(index, "non")
                            }
                          />
                          <label
                            className="cursor-pointer select-none"
                            htmlFor={`horizontal-non-${index}`}
                          >
                            Non
                          </label>
                        </div>
                      </div>
                      {associesParts[index]["associe-part"] !== undefined &&
                      associesParts[index]["associe-part"] === "oui" ? (
                        <div className="grid grid-cols-12 gap-4 row-gap-5 col-span-12">
                          <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="my-2">Indiquez l’associé</div>
                            <select
                              type="text"
                              className="input w-full border flex-1 field"
                              onChange={(e) =>
                                handleTypeGerant(index, e.target.value)
                              }
                            >
                              {associesPart?.map((associe, index) => {
                                return (
                                  <option
                                    value={Utils.Demande.getAssocieName(associe)}
                                    key={index}
                                  >
                                    {Utils.Demande.getAssocieName(associe)}
                                    {/* {associe["nom"] || associe["prenoms"]?(

                                  associe["nom"]  associe["prenoms"]
                                    ):null}
                                    {`${associe["nom-representant"]}`} */}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="my-2">
                              Indiquez le vendeur de part
                            </div>
                            <select
                              type="text"
                              className="input w-full border flex-1 field"
                              onChange={(e) =>
                                handleInputAssociePartChange(
                                  index,
                                  "vendeur",
                                  e.target.value
                                )
                              }
                            >
                              <option value=""></option>

                              {associesPartVendeur?.map(
                                (associeVendeur, index) => (
                                  <option
                                    value={associeVendeur["nom-prenom-vendeur"]}
                                    key={index}
                                  >
                                    {associeVendeur["nom-prenom-vendeur"]}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          <div className="intro-y col-span-12 sm:col-span-6">
                            <div className="mb-2">Pourcentage à acheter </div>
                            <input
                              type="number"
                              className="input w-full border flex-1 field"
                              placeholder="Pourcentage à acheter"
                              onChange={(e) =>
                                handleInputAssociePartChange(
                                  index,
                                  "pourcentage-parts",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      ) : null}
                      {associesParts[index]["associe-part"] !== undefined &&
                      associesParts[index]["associe-part"] === "non" ? (
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey={index}>
                            <Accordion.Header>
                              Qui est l'acheteur {index + 1}
                            </Accordion.Header>
                            <Accordion.Body>
                              <div
                                className="intro-y col-span-12"
                                key={index}
                                id={`${index}`}
                              >
                                <div className="grid grid-cols-12 mx-auto max-w-md  items-center mt-4 mb-2">
                                  <div className="box px-4 py-4 mb-3 col-span-8  items-center zoom-in ">
                                    <input
                                      type="radio"
                                      className="overlay opacity-0"
                                      id={`horizontal-radio-particulier-${index}`}
                                      name={`associe-${index}`}
                                      value="particulier"
                                      onChange={(e) =>
                                        handleTypeGerant(index, "particulier")
                                      }
                                    />
                                    <div className="radio-circle">
                                      <div className="radio-circle__outer-circle">
                                        <div className="radio-circle__inner-circle"></div>
                                      </div>
                                    </div>

                                    <div className="ml-2 mr-auto">
                                      <div className="font-medium">
                                        Un particulier
                                      </div>
                                    </div>
                                  </div>

                                  <div className="box px-4 py-4 mb-3 col-span-8  items-center zoom-in ">
                                    <input
                                      type="radio"
                                      className="overlay opacity-0"
                                      id={`horizontal-radio-societe-${index}`}
                                      name={`associe-${index}`}
                                      value="societe"
                                      onChange={(e) =>
                                        handleTypeGerant(index, "societe")
                                      }
                                    />
                                    <div className="ml-2 mr-auto">
                                      <div className="font-medium">
                                        Une société
                                      </div>
                                    </div>

                                    <div className="radio-circle">
                                      <div className="radio-circle__outer-circle">
                                        <div className="radio-circle__inner-circle"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 ${
                                    associesParts !== undefined &&
                                    associesParts[index].type === "particulier"
                                      ? ""
                                      : "hidden"
                                  }`}
                                >
                                  <div className="intro-y col-span-12 sm:col-span-6">
                                    <div className="my-2">Civilité</div>
                                    <select
                                      type="text"
                                      className="input w-full border flex-1 field"
                                      onChange={(e) =>
                                        handleInputAssociePartChange(
                                          index,
                                          "civilite",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="monsieur">Monsieur</option>
                                      <option value="madame">Madame</option>
                                      <option value="mademoiselle">
                                        Mademoiselle
                                      </option>
                                    </select>
                                  </div>
                                  {associesParts !== undefined &&
                                  associesParts[index].type ===
                                    "particulier" ? (
                                    <>
                                      <div className="intro-y col-span-12 sm:col-span-6">
                                        <div className="mb-2">Nom</div>
                                        <input
                                          type="text"
                                          className="input w-full border flex-1 field"
                                          placeholder="Nom"
                                          onChange={(e) =>
                                            handleInputAssociePartChange(
                                              index,
                                              "nom",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="intro-y col-span-12 sm:col-span-6">
                                        <div className="mb-2">Prénom(s)</div>
                                        <input
                                          type="text"
                                          className="input w-full border flex-1 field"
                                          placeholder="Prénom(s)"
                                          onChange={(e) =>
                                            handleInputAssociePartChange(
                                              index,
                                              "prenoms",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>

                                      <div className="intro-y col-span-12 sm:col-span-6">
                                        <div className="my-2">
                                          Indiquez le vendeur de part
                                        </div>
                                        <select
                                          type="text"
                                          className="input w-full border flex-1 field"
                                          onChange={(e) =>
                                            handleInputAssociePartChange(
                                              index,
                                              "vendeur",
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value=""></option>

                                          {associesPartVendeur?.map(
                                            (associeVendeur, index) => {
                                              return (
                                                <>
                                                  <option
                                                    value={
                                                      associeVendeur[
                                                        "nom-prenom-vendeur"
                                                      ]
                                                    }
                                                    key={index}
                                                  >
                                                    {
                                                      associeVendeur[
                                                        "nom-prenom-vendeur"
                                                      ]
                                                    }
                                                  </option>
                                                </>
                                              );
                                            }
                                          )}
                                        </select>
                                      </div>
                                      <div className="intro-y col-span-12 sm:col-span-6">
                                        <div className="mb-2">
                                          Pourcentage à acheter{" "}
                                        </div>
                                        <input
                                          type="number"
                                          className="input w-full border flex-1 field"
                                          placeholder="Pourcentage à acheter"
                                          onChange={(e) =>
                                            handleInputAssociePartChange(
                                              index,
                                              "pourcentage-parts",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                                <div
                                  className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12
               ${
                 associesParts !== undefined &&
                 associesParts[index].type === "societe"
                   ? ""
                   : "hidden"
               }`}
                                >
                                  <div className="intro-y col-span-12 sm:col-span-6">
                                    <div className="mb-2">
                                      Dénomination sociale{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="input w-full border flex-1 field"
                                      placeholder="Dénomination sociale"
                                      onChange={(e) =>
                                        handleInputAssociePartChange(
                                          index,
                                          "denomination-sociale",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="intro-y col-span-12 sm:col-span-6">
                                    <div className="mb-2">
                                      Quelle est sa forme sociale ?
                                    </div>
                                    <input
                                      type="text"
                                      className="input w-full border flex-1 field"
                                      placeholder="Quelle est sa forme sociale ?"
                                      onChange={(e) =>
                                        handleInputAssociePartChange(
                                          index,
                                          "forme-sociale",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="intro-y col-span-12 sm:col-span-6">
                                    <div className="my-2">
                                      Indiquez le vendeur de part
                                    </div>
                                    <select
                                      type="text"
                                      className="input w-full border flex-1 field"
                                      onChange={(e) =>
                                        handleInputAssociePartChange(
                                          index,
                                          "vendeur",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value=""></option>

                                      {associesPartVendeur?.map(
                                        (associeVendeur, index) => {
                                          return (
                                            <>
                                              <option
                                                value={
                                                  associeVendeur[
                                                    "nom-prenom-vendeur"
                                                  ]
                                                }
                                                key={index}
                                              >
                                                {
                                                  associeVendeur[
                                                    "nom-prenom-vendeur"
                                                  ]
                                                }
                                              </option>
                                            </>
                                          );
                                        }
                                      )}
                                    </select>
                                  </div>
                                  <div className="intro-y col-span-12 sm:col-span-6">
                                    <div className="mb-2">
                                      Pourcentage à acheter{" "}
                                    </div>
                                    <input
                                      type="number"
                                      className="input w-full border flex-1 field"
                                      placeholder="Pourcentage à acheter"
                                      onChange={(e) =>
                                        handleInputAssociePartChange(
                                          index,
                                          "pourcentage-parts",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            ) : null}

            {val.value.includes("augmente mon capital social") ? (
              <div className="grid grid-cols-12 gap-4 row-gap-5 col-span-12">
                <div className="intro-y col-span-12">
                  <hr />
                </div>
                <div className="intro-y col-span-12 text-lg ">
                  <strong>
                    Est-ce que de nouveaux associés sont autorisés à souscrire
                    au moment de l’augmentation ?
                  </strong>
                </div>
                <div className="intro-y flex col-span-12 sm:col-span-6 my-5">
                  <div className="intro-y flex col-span-12 sm:col-span-6 mr-8">
                    <input
                      type="radio"
                      className="input border mr-2"
                      name={`part-associe`}
                      value="non"
                      onChange={(e) => handleStatePart(e.target.value)}
                    />
                    <label
                      className="cursor-pointer select-none"
                      htmlFor={`horizontal-radio-oui`}
                    >
                      Non
                    </label>
                  </div>
                  <div className="intro-y flex col-span-12 sm:col-span-6 ">
                    <input
                      type="radio"
                      className="input border mr-2"
                      id={`horizontal-non`}
                      name={`part-associe`}
                      value="oui"
                      onChange={(e) => handleStatePart(e.target.value)}
                    />
                    <label
                      className="cursor-pointer select-none"
                      htmlFor={`horizontal-non`}
                    >
                      Oui
                    </label>
                  </div>
                </div>

                <div className="intro-y grid grid-cols-12 col-span-12">
                  {statePartAutorise ? (
                    <div className="intro-y col-span-12 sm:col-span-6">
                      <div className="mb-2 ">
                        Indiquez le nombre d’associés :
                      </div>
                      <input
                        type="number"
                        className="input w-full border flex-1 field"
                        placeholder="Nombre associé"
                        onChange={(e) =>
                          setNombreAssociesAutorise(e.target.value)
                        }
                      />
                    </div>
                  ) : null}
                </div>
                {statePartAutorise &&
                  associesAutorise &&
                  associesAutorise.map((associe, index) => {
                    return (
                      <div className="intro-y col-span-12" key={index}>
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey={index}>
                            <Accordion.Header>
                              Identité du nouvel associé (numéro {index + 1})
                            </Accordion.Header>
                            <Accordion.Body>
                              <div
                                className="intro-y col-span-12"
                                key={index}
                                id={`${index}`}
                              >
                                <div className="grid grid-cols-12 mx-auto max-w-md  items-center mt-4 mb-2">
                                  <div className="box px-4 py-4 mb-3 col-span-8  items-center zoom-in ">
                                    <input
                                      type="radio"
                                      className="overlay opacity-0"
                                      id={`horizontal-radio-particulier-${index}`}
                                      name={`associe-${index}`}
                                      value="particulier"
                                      onChange={(e) =>
                                        handleTypeAssocieChange(
                                          index,
                                          "particulier"
                                        )
                                      }
                                    />
                                    <div className="radio-circle">
                                      <div className="radio-circle__outer-circle">
                                        <div className="radio-circle__inner-circle"></div>
                                      </div>
                                    </div>

                                    <div className="ml-2 mr-auto">
                                      <div className="font-medium">
                                        Un particulier
                                      </div>
                                    </div>
                                  </div>

                                  <div className="box px-4 py-4 mb-3 col-span-8  items-center zoom-in ">
                                    <input
                                      type="radio"
                                      className="overlay opacity-0"
                                      id={`horizontal-radio-societe-${index}`}
                                      name={`associe-${index}`}
                                      value="societe"
                                      onChange={(e) =>
                                        handleTypeAssocieChange(
                                          index,
                                          "societe"
                                        )
                                      }
                                    />
                                    <div className="ml-2 mr-auto">
                                      <div className="font-medium">
                                        Une société
                                      </div>
                                    </div>

                                    <div className="radio-circle">
                                      <div className="radio-circle__outer-circle">
                                        <div className="radio-circle__inner-circle"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 ${
                                    associesAutorise !== undefined &&
                                    associesAutorise[index].type ===
                                      "particulier"
                                      ? ""
                                      : "hidden"
                                  }`}
                                >
                                  <div className="intro-y col-span-12 sm:col-span-6">
                                    <div className="my-2">
                                      Civilité de l'appoteur
                                    </div>
                                    <select
                                      className="input w-full border flex-1 field"
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "civilite-apporteur",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="monsieur">Monsieur</option>
                                      <option value="madame">Madame</option>
                                      <option value="mademoiselle">
                                        Mademoiselle
                                      </option>
                                    </select>
                                  </div>
                                  <div className="intro-y col-span-12 sm:col-span-6">
                                    <div className="mb-2">
                                      Nom de l'appoteur
                                    </div>
                                    <input
                                      type="text"
                                      className="input w-full border flex-1 field"
                                      placeholder="Nom"
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "nom-apporteur",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="intro-y col-span-12 sm:col-span-6">
                                    <div className="mb-2">
                                      Prénom(s) de l'appoteur
                                    </div>
                                    <input
                                      type="text"
                                      className="input w-full border flex-1 field"
                                      placeholder="Prénom(s)"
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "prenoms-apporteur",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="intro-y col-span-12 sm:col-span-6">
                                    <div className="mb-2">
                                      Quel est le montant de l’apport du nouvel
                                      associé :
                                    </div>
                                    <input
                                      type="number"
                                      className="input w-full border flex-1 field"
                                      placeholder="5 000 000 XOF"
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "apport-nouvel-associe",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div
                                  className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12
               ${
                 associesAutorise !== undefined &&
                 associesAutorise[index].type === "societe"
                   ? ""
                   : "hidden"
               }`}
                                >
                                  <div className="intro-y col-span-12 sm:col-span-6">
                                    <div className="mb-2">
                                      Dénomination sociale{" "}
                                    </div>
                                    <input
                                      type="text"
                                      className="input w-full border flex-1 field"
                                      placeholder="Dénomination sociale"
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "denomination-sociale",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="intro-y col-span-12 sm:col-span-6">
                                    <div className="mb-2">
                                      Quelle est sa forme sociale ?
                                    </div>
                                    <input
                                      type="text"
                                      className="input w-full border flex-1 field"
                                      placeholder="Quelle est sa forme sociale ?"
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "forme-sociale",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="intro-y col-span-12 sm:col-span-6">
                                    <div className="my-2">
                                      Civilité du répresentant légal
                                    </div>
                                    <select
                                      type="text"
                                      className="input w-full border flex-1 field"
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "civilite-apporteur",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="monsieur">Monsieur</option>
                                      <option value="madame">Madame</option>
                                      <option value="mademoiselle">
                                        Mademoiselle
                                      </option>
                                    </select>
                                  </div>
                                  <div className="intro-y col-span-12 sm:col-span-6">
                                    <div className="mb-2">
                                      Nom du répresentant légal
                                    </div>
                                    <input
                                      type="text"
                                      className="input w-full border flex-1 field"
                                      placeholder="Nom"
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "nom",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="intro-y col-span-12 sm:col-span-6">
                                    <div className="mb-2">
                                      Prénom(s) du répresentant légal
                                    </div>
                                    <input
                                      type="text"
                                      className="input w-full border flex-1 field"
                                      placeholder="Prénom(s)"
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "prenoms",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="intro-y col-span-12 sm:col-span-6">
                                    <div className="mb-2">
                                      Quel est le montant de l’apport du du
                                      répresentant légal :
                                    </div>
                                    <input
                                      type="number"
                                      className="input w-full border flex-1 field"
                                      placeholder="5 000 000 XOF"
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "apport-nouvel-associe",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    );
                  })}
              </div>
            ) : null}
          </Fragment>
        );
      })}

      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
