import { useEffect, useState } from "react";
import * as Icons from "react-feather";
import filePlaceholderImage from "../assets/images/profile-7.jpg";

export function PieceItemRecouv(props) {
  // console.log("PieceItem", props);
  const [canShowFileInput, setCanShowFileInput] = useState(true);
  const { type_piece } = props;
  // console.log("type_piece", type_piece);

  const handlePieceUpload = (e, associeIndex, type_piece) => {
    // console.log('azaz11',associeIndex, type_piece);

    props.handlePieceUpload(e, props.associeIndex ?? null, type_piece);
    setCanShowFileInput(false);
  };

  const handlePieceDeleted = (e, associeIndex, typePieceIndex) => {
    // console.log('azaz22',associeIndex, typePieceIndex);
    // props.handlePieceDelete(e, associeIndex, typePieceIndex);
    // if (!typePieceIndex || !typePieceIndex) return;
    setCanShowFileInput(true);
  };
  // console.log("canShowFileInput", canShowFileInput);

  return (
    <li className="grid grid-cols-12 col-span-12 my-1">
      
      <div className="col-span-4 pt-2">
        
        <strong>
          {type_piece.libelle === "Factures" ? (
            <div className="grid grid-cols-12 gap-4  col-span-12">
              <div className="intro-y col-span-2 sm:col-span-3">Factures</div>
              <div className="intro-y col-span-4 sm:col-span-8">
                (Veuillez joindre toutes les factures en un seul document pour
                les téléverser)
              </div>
            </div>
          ) : (
            <div className="intro-y col-span-6 ">{type_piece.libelle}</div>
          )}
        </strong>
        
      </div>
      
      <div className="col-span-8">
        {!canShowFileInput ? (
          <div className="w-20 h-20 relative image-fit cursor-pointer zoom-in ml-2">
            <img
              className="rounded-md"
              alt={type_piece.libelle}
              src={filePlaceholderImage}
            />
            <div
              title="Retirer"
              className="tooltip w-5 h-5 flex items-center 
                        justify-center absolute rounded-full text-white bg-theme-6 
                        right-0 top-0 -mr-2 -mt-2"
            >
              <Icons.X
                className="w-4 h-4"
                onClick={(e) =>
                  handlePieceDeleted(
                    e,
                    props.associeIndex,
                    props.typePieceIndex
                  )
                }
              />
            </div>
          </div>
        ) : (
          <input
            type="file"
            className="input w-full border flex-1 field"
            accept="image/*, .pdf, .docx"
            disabled={props.isDisabled}
            capture="environment"
            onChange={(e) =>
              handlePieceUpload(e, props.associeIndex ?? null, type_piece)
            }
          />
        )}
      </div>
    
    </li>
    
  );
}
