import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Services } from "../services";
import { Utils } from "../utils";
import { Components } from ".";
import * as Icons from "react-feather";

export function AjoutDocumentStepRecouv(props) {
  const abortController = useMemo(() => new AbortController(), []);
  const { associes } = props.useDemande?.champs_questionnaire
    ? JSON.parse(props.useDemande.champs_questionnaire)
    : [];

  const [piecesByAssocies, setPiecesByAssocies] = useState([]);
  const [autrePieces, setAutrePieces] = useState({});
  const [myTypePieces, setMyTypePieces] = useState([]);
  const [pieces, setPieces] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  // let myada = [];
  const handlePieceDelete = (e, associeIndex, pieceIndex) => {
    e.preventDefault();

    const piecesByAssociesCopy = piecesByAssocies.map((piecesByAssocie) => {
      const { associe, type_pieces } = piecesByAssocie;
      return { associe, type_pieces: [...type_pieces] };
    });

    piecesByAssociesCopy[associeIndex]["type_pieces"][pieceIndex]["piece"] = {};
    setPiecesByAssocies([...piecesByAssociesCopy]);
  };
  const handlePieceUpload = async (e, associeIndex, type_piece) => {
    e.preventDefault();
    setIsDisabled(true);

    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    try {
      const { file_url } = await Services.FileService.upload(
        formData,
        abortController.signal
      );

      const payload = {
        type_piece_id: type_piece.id,
        demande_id: props.useDemande.id,
        piece_url: file_url,
      };

      const { piece } = await Services.PieceService.create(
        JSON.stringify(payload),
        abortController.signal
      );
      // const piecesByAssociesCopy = piecesByAssocies.map((piecesByAssocie) => {
      //   const { associe, type_pieces } = piecesByAssocie;
      //   return { associe, type_pieces: [...type_pieces] };
      // });
      // const type_pieceIndex = piecesByAssociesCopy[
      //   associeIndex
      // ].type_pieces.findIndex(
      //   (type_piece) => type_piece.type_piece.id === piece.type_piece_id
      // );

      // if (type_pieceIndex >= 0) {
      //   piecesByAssociesCopy[associeIndex]["type_pieces"][type_pieceIndex][
      //     "piece"
      //   ] = piece;
      //   setPiecesByAssocies(piecesByAssociesCopy);
      // }

      // setPieces([...pieces, piece]);
    } catch (error) {
      alert(String(error));
      if ("messages" in error) return;
      //Toaster
    } finally {
      setIsDisabled(false);
    }
  };

  // const handleAutrePieceUpload = async (e, nullParam, type_piece) => {
  //   e.preventDefault();
  //   setIsDisabled(true);

  //   const formData = new FormData();
  //   formData.append("file", e.target.files[0]);

  //   try {
  //     const { file_url } = await Services.FileService.upload(
  //       formData,
  //       abortController.signal
  //     );

  //     const payload = {
  //       type_piece_id: type_piece.id,
  //       demande_id: props.useDemande.id,
  //       piece_url: file_url,
  //     };

  //     const { piece } = await Services.PieceService.create(
  //       JSON.stringify(payload),
  //       abortController.signal
  //     );
  //     const autrePiecesCopy = {
  //       type_pieces: [...autrePieces.type_pieces],
  //     };
  //     const type_pieceIndex = autrePiecesCopy.type_pieces.findIndex(
  //       (type_piece) => type_piece.type_piece.id === piece.type_piece_id
  //     );

  //     if (type_pieceIndex >= 0) {
  //       autrePiecesCopy["type_pieces"][type_pieceIndex]["piece"] = piece;
  //       setAutrePieces(autrePiecesCopy);
  //     }

  //     setPieces([...pieces, piece]);
  //   } catch (error) {
  //     if ("messages" in error) return;
  //     //Toaster
  //   } finally {
  //     setIsDisabled(false);
  //   }
  // };

  const handlePieceSubmit = async (e) => {
    e.preventDefault();

    // if (pieces.length < 1)
    //   return alert("Vous devez importer au moins un document !");

    setIsDisabled(true);
    props.handleSubmit();
  };

  const initialize = useCallback(async () => {
    try {
      let { pieces } = await Services.DemandeService.getPieces(
        props.useDemande.id,
        abortController.signal
      );
      const { type_pieces } = await Services.TypeDemandeService.getTypePieces(
        props.useDemande.type_demande_id,
        abortController.signal
      );

      // const { pays } = await Services.PaysService.getAll(
      //   abortController.signal
      // );

      setMyTypePieces(type_pieces);
      // console.log("myTypePieces", myTypePieces);
      // setPieces([...pieces]);
      // const associesCopy = associes.length >= 1 ? [...associes] : [{}];
      // let autrePieces = {};
      // let autreTypePieces = [];
      // let piecesByAssocies = [];

      // associesCopy.forEach((associe, index) => {
      //   let typePieces = [];
      //   piecesByAssocies[index] = { associe };

      //   type_pieces.forEach((type_piece, jndex) => {
      //     const pieceIndex = pieces.findIndex(
      //       (pieceItem) => pieceItem.type_piece_id === type_piece.id
      //     );

      //     const libelle = type_piece.libelle;

      //     if (
      //       libelle.includes("localisation") ||
      //       libelle.includes("propriété ou de bail") ||
      //       libelle.includes("location-gérance") ||
      //       libelle.includes("Facture") ||
      //       libelle.includes("Contrat de Bail") ||
      //       libelle.includes("Justificatif de domicile") ||
      //       libelle.includes("un plan de situation ")
      //     ) {
      //       autreTypePieces[jndex] = {
      //         type_piece,
      //         piece:
      //           pieceIndex >= 0
      //             ? pieces[pieceIndex]
      //             : autreTypePieces[jndex]?.piece,
      //       };
      //     } else {
      //       typePieces[jndex] = {
      //         type_piece,
      //         piece: pieceIndex >= 0 ? pieces[pieceIndex] : {},
      //       };
      //     }

      //     pieces[pieceIndex] = {};
      //   });
      //   piecesByAssocies[index]["type_pieces"] = typePieces.filter(
      //     (typePiece) => typePiece !== undefined
      //   );
      // });

      // autrePieces["type_pieces"] = autreTypePieces;

      // setAutrePieces(autrePieces);

      // setPiecesByAssocies(piecesByAssocies);
    } catch (error) {
      if ("messages" in error) return;
      //Toaster
    }
  }, [abortController]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <div className="grid grid-cols-12 mt-5">
      <div className="col-span-12 my-2">
        <div
          className="rounded-md flex items-center px-5 py-4 mb-2 bg-theme-31 
                text-theme-6"
        >
          {" "}
          <Icons.AlertTriangle className="w-6 h-6 mr-2" /> Si votre pièce est en
          une autre langue que le français veuillez ajouter la traduction
          légalisée de la pièce aux documents avant de le joindre
        </div>
      </div>

      <ul className="grid grid-cols-12 col-span-12 lg:col-span-8">
        {myTypePieces.map((type_piece, index) => {
          return (
            <Fragment key={index}>
              <Components.PieceItemRecouv
                type_piece={type_piece}
                typePieceIndex={index}
                isDisabled={isDisabled}
                handlePieceUpload={handlePieceUpload}
                handlePieceDelete={handlePieceDelete}
              />
            </Fragment>
          );
        })}
      </ul>

      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handlePieceSubmit}
          disabled={isDisabled}
        >
          {isDisabled ? "Chargement..." : "Enregister"}
        </button>
      </div>
    </div>
  );
}
