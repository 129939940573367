import { useState } from "react";
import { Components } from "../../../..";
import { Utils } from "../../../../../utils";

export function RecapStepSarlMaV2(props) {
  const { isJsonParseable, parseJson } = Utils.String;

  const [modalTitle, setModalTitle] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [valueName, setValueName] = useState("");
  const [valueArrayIndex, setValueArrayIndex] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const { denomination, banque } = props.champsDemandeObject;
  const capital = props.champsDemandeObject["capital-social"]
    ?.toString()
    .replace(/ /gi, "");
  const { associes, sigle, activites, adresse, gerants } =
    props.questionnaireObject;

  const associesArray = isJsonParseable(associes) ? parseJson(associes) : [];
  const gerantsArray = isJsonParseable(gerants) ? parseJson(gerants) : [];
  const adresseObject = {
    ...parseJson(props.questionnaireObject?.adresse1),
    ...parseJson(adresse),
  };
  const apportCapital = associesArray.map((item) => {
    return {
      associe: Utils.Demande.getAssocieName(item),
      montant: `${item.apport ?? parseInt(capital)} MAD`,
      pourcentage: Math.round(
        ((item.apport ?? parseInt(capital)) * 100) / parseInt(capital ?? 0)
      ),
    };
  });
  const infosGenerales = {
    premiere_proposition: denomination[0] ?? "",
    deuxieme_proposition: denomination[1] ?? "",
    troisieme_proposition: denomination[2] ?? "",
    sigle: parseJson(sigle)?.sigle,
    sigle_obligatoire: parseJson(sigle)?.["sigle-obligatoire"] ?? "",
    activites: activites,
    banque: banque,
  };
  const handleEditClick = (e, name, index = "") => {
    e.preventDefault();

    setIsEditModalOpen(true);
    setValueName(name);
    setValueArrayIndex(index);
  };
  const handleStepSubmit = async (e) => {
    e.preventDefault();
    //console.log("e", e);

    props.handleSubmit(e, "", "");
    setIsDisabled(true);
  };

  return (
    <div
      className={`grid grid-cols-1 col-span-12 
        ${props.step !== props.index ? "hidden" : ""} `}
    >
      <div className="flex-content-column col-span-12">
        <Components.RecaptItem
          title={"Informations relatives aux Associés"}
          name={"associes"}
          value={associesArray}
          hasEditIcon={true}
          handleEditClick={handleEditClick}
        />

        <Components.InfoBox title={"Apport au capital"}>
          <div className="font-medium text-base col-span-12 mb-1">
            Montant du capital: {parseInt(capital) ?? ""} MAD
          </div>
          <div className="grid grid-cols-12 gap-6">
            <Components.Table
              tableAttributes={{ associe: {}, montant: {}, pourcentage: {} }}
              tableData={apportCapital}
              tableActions={[]}
              controllers={{
                handleEditClick: null,
                handleReadClick: null,
                handleDeleteClick: null,
              }}
            />
          </div>
        </Components.InfoBox>

        <Components.RecaptItem
          title={"Informations générales"}
          name={"infos"}
          value={infosGenerales}
          hasEditIcon2={true}
          handleEditClick={handleEditClick}
        />

        <Components.RecaptItem
          title={"Adresse de la société"}
          name={"adresse"}
          value={
            props.champsDemandeObject["localisation-siege"].includes("Legafrik")
              ? "Domicilié à Legafrik"
              : adresseObject
          }
          hasEditIcon2={
            !props.champsDemandeObject["localisation-siege"].includes(
              "Legafrik"
            )
          }
          handleEditClick={handleEditClick}
        />

        <Components.RecaptItem
          title={"Informations relatives à la gérance"}
          name={"gerants"}
          value={gerantsArray}
          hasEditIcon={true}
          handleEditClick={handleEditClick}
        />
      </div>

      <div
        className="intro-y col-span-12 flex items-center justify-center
            sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 
                text-gray-600 dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>

        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
          disabled={isDisabled}
        >
          {isDisabled ? " Chargement en cours..." : "Suivant"}
        </button>
      </div>
      {isEditModalOpen ? (
        <Components.Modal
          handleModalClose={() => setIsEditModalOpen(false)}
          handleModalValidate={() => setIsEditModalOpen(false)}
          title={modalTitle ?? "Modification du questionnaire"}
        >
          <Components.RecapEditForm
            handleSubmit={props.handleNextClick}
            value={props.questionnaireObject[valueName]}
            valueName={valueName}
            valueArrayIndex={valueArrayIndex}
            setIsEditModalOpen={setIsEditModalOpen}
            activites={activites}
            sigle={sigle}
            currency="MAD"
          />
        </Components.Modal>
      ) : null}
    </div>
  );
}
